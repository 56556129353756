import { takeLatest, call, select, put } from 'redux-saga/effects';
import qs from 'querystring';
import { getWhereSearch, UPDATE_SELECTION, REMOVE_SELECTION } from '../reducers/cartoParams';
import { LOAD_COUNT_DICTIONARY_SUCCESS, LOAD_COUNT_DICTIONARY } from '../reducers/dictionaries';
import { COMPLETE_INITIAL_LOAD } from '../reducers/loadCarto';
import config from '../config/default';
import buildPlaceSql from '../tools/buildPlaceSql';

const { tableSuffix } = config;

// request data from Carto
function fetchCodeCount({ where }) {
  // build SQL strings for each search level, if a parameter is specified
  const { nfpanumberSql } = buildPlaceSql(where, true);

  // build the SQL
  const sql = `
    SELECT *, COUNT(*)
    FROM (
      SELECT
        'country-'||countrynames.long country,
        'state-'||statenames.name state,
        'county-'||county.name county,
        'city-'||city.name city,
        'agency-'||org.name agency,
        'deaid' deaid
        ${where.nfpanumber ? ', \'nfpanumber-\'||doc.number nfpanumber' : ''}
      FROM nfpa_organization${tableSuffix} org
        LEFT OUTER JOIN nfpa_county${tableSuffix} county ON org.county_id = county.county_id
        LEFT OUTER JOIN nfpa_city${tableSuffix} city ON org.city_id = city.city_id
        INNER JOIN document_edition_adoption${tableSuffix} dea ON dea.agencyid = org.organization_id AND dea.status = 'Current Adoption'
        INNER JOIN document_edition${tableSuffix} de ON de.documenteditionid = dea.documenteditionid
        INNER JOIN document${tableSuffix} doc ON doc.documentid = de.documentid
        LEFT OUTER JOIN country_translation countrynames ON countrynames.short = org.country_code
        LEFT OUTER JOIN ne_50m_admin_1_states_provinces_lakes statenames ON statenames.postal = org.region_code AND statenames.iso_a2 = org.country_code
      WHERE de.codenumberprefix NOT IN ('IBCO','NBCC','NFCC','IAPMO','ICC','ICC ')
    ) results
    WHERE agency != 'agency-Agency not identified'${nfpanumberSql}
    GROUP BY country, state, county, city, agency, deaid
      ${where.nfpanumber ? ', nfpanumber' : ''}
    ORDER BY (CASE country when 'country-United States of America' THEN 0 ELSE 1 END), country, state, county, city, agency
  `;
  return fetch(`https://${config.username}.carto.com/api/v2/sql?${qs.stringify({
    api_key: config.cartoKey,
    q: sql,
  })}`)
    .then((response) => response.json())
    .catch((error) => console.log('code count error: ', error.message));
}

// worker saga that fires the api call function when the watcher saga sees the action
function* loadCodeCount() {
  yield put({ type: LOAD_COUNT_DICTIONARY });
  try {
    // get where params
    const where = yield select(getWhereSearch);

    // fire loadCarto request, with specific requests as necessary
    const resultObj = yield call(fetchCodeCount, { where });
    const rawResults = resultObj.rows;

    // sum up counts of codes by value name
    const countDictionary = {};
    rawResults.forEach((result) => {
      if (!result.count) return;
      Object.keys(result).forEach((key) => {
        const value = result[key];
        if (value && key !== 'count') {
          if (!countDictionary[value]) countDictionary[value] = result.count;
          else countDictionary[value] += result.count;
        }
      });
    });

    // save code counts to state
    yield put({ type: LOAD_COUNT_DICTIONARY_SUCCESS, payload: countDictionary });
  } catch (error) {
    // dispatch error action with error to store
    console.error(`Load code counts error: ${error}`);
  }
}

// watcher function to track actions to store, start loadCarto saga
export default function* watchForCodeCount() {
  yield takeLatest([COMPLETE_INITIAL_LOAD, UPDATE_SELECTION, REMOVE_SELECTION], loadCodeCount);
}
