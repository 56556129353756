import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import _ from 'lodash';

import Flag from '@material-ui/icons/Flag';
import Button from '@material-ui/core/Button';
import Right from '@material-ui/icons/KeyboardArrowRight';

import ReferencedCodes from './ReferencedCodes';
import ArrowTooltip from '../../../ArrowTooltip';

import { updateSelection } from '../../../../reducers/cartoParams';
import config from '../../../../config/default';
import splitCartoResult from '../../../../tools/splitCartoResult';

const { withdrawnCodes } = config;

const styles = (theme) => ({
  code: {
    minHeight: '45px',
    padding: '5px 5px 0 0',
    fontWeight: 'normal',
    borderBottom: '1px solid',
  },
  codeEdition: { fontSize: '0.9em' },
  ammended: {
    margin: '3px 0 -5px 3px',
    color: '#d50032',
  },
  link: {
    fontSize: '0.9em',
    color: '#002c5f',
    fontWeight: 'bold',
    textDecoration: 'underline',
    '&:hover': {
      color: '#64a0c8',
    },
  },
  mapIt: { marginLeft: theme.spacing.unit, padding: 4 },
  icon: { maringLeft: theme.spacing.unit },
});

const CodeRow = ({ classes, code, noParent, other, width, updateSelection, nfpanumber }) => (
  <div className={classes.code}>
    {noParent || other ? <div><strong>Referenced by: {code.referencedtype}</strong></div> : <span />}
    <div>
      <CodeLink classes={classes} code={code} codeType={code.codetype} noParent={noParent} other={other} />
      <AmendmentFlag classes={classes} amended={code.amended === '1'} />
      {width !== 'xs' && !process.env.REACT_APP_NEC ? (
        <ArrowTooltip title={code.nfpanumber.split(/-(.+)/)[1] === nfpanumber ? 'Code already mapped' : 'Map code editions'}>
          <span>
            <Button
              className={classes.mapIt}
              disabled={code.nfpanumber.split(/-(.+)/)[1] === nfpanumber}
              onClick={() => updateSelection(code)}
            >
              Map It <Right fontSize="small" className={classes.icon} />
            </Button>
          </span>
        </ArrowTooltip>
      ) : null}
    </div>
    <div>
      <span className={classes.codeEdition}>Edition: {code.edition}</span>
      { (code.reference && code.reference[0] !== null && code.reference[0] !== 'NULL') ? <ReferencedCodes code={code.title} referencedCodes={code.reference.filter((code) => !_.includes(withdrawnCodes, code))} /> : <span /> }
    </div>
  </div>
);

// Code link back to NFPA doc-info page
const CodeLink = ({ classes, code, codeType, noParent, other }) => {
  let linkPath = splitCartoResult(code.nfpanumber, 1);
  let linkTitle = 'View at NFPA.org';
  // specialty code handling situations
  if (codeType.match(/ICC.*/)) {
    linkPath = 'Codes-and-Standards/Standards-development-process/The-value-of-Standards-Development-Organizations/ICC';
  }
  if (codeType === 'IAPMO') {
    linkPath = 'Codes-and-Standards/Standards-development-process/The-value-of-Standards-Development-Organizations/IAPMO';
  }
  if (_.includes(['IBCO', 'NBCC', 'NFCC'], code.nfpanumber.split(/-(.+)/)[1])) {
    linkPath = '';
  }
  if (_.includes(withdrawnCodes, code.nfpanumber.split(/-(.+)/)[1])) {
    linkPath = '';
    linkTitle = 'This code has been withdrawn';
  }
  return (
    <ArrowTooltip title={linkTitle}>
      <a className={classes.link} href={`https://www.nfpa.org/${linkPath}`} target="_blank" rel="noopener noreferrer">
        {_.includes(['IBCO', 'NBCC', 'NFCC', 'IAPMO', 'ICC', 'ICC '], code.codetype) ? '' : 'NFPA'} {code.nfpanumber.split(/-(.+)/)[1]}
        {code.suffix !== null && code.suffix !== 'NULL' ? code.suffix : ''} {code.title ? code.title : code.titlealt}
      </a>
    </ArrowTooltip>
  );
};

// Flag indicating ammendments
const AmendmentFlag = ({ classes, amended }) => {
  if (!amended) return <span />;
  return (
    <ArrowTooltip title="This code has been amended. Refer to the agency using this code for more information.">
      <Flag className={classes.ammended} />
    </ArrowTooltip>
  );
};

const mapStateToProps = ({ cartoParams }) => ({ nfpanumber: cartoParams.where.nfpanumber });

const mapDispatchToProps = (dispatch) => ({ updateSelection: (payload) => dispatch(updateSelection(payload)) });

export default withStyles(styles)(withWidth()(connect(mapStateToProps, mapDispatchToProps)(CodeRow)));
