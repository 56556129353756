import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

import ArrowTooltip from '../../../ArrowTooltip';

import { toggleMapVisibility } from '../../../../reducers/map';

const styles = (theme) => ({
  root: {
    margin: 0,
    marginRight: theme.spacing.unit,
    height: 30,
  },
  label: {
    color: 'white',
    fontSize: 10,
  },
});

const MapToggle = ({ classes, visible, toggleMapVisibility }) => (
  <ArrowTooltip title={visible ? 'Hide Map' : 'Show Map'} adjust>
    <FormControlLabel
      classes={{ root: classes.root }}
      control={(
        <Switch
          checked={visible}
          onChange={(e) => toggleMapVisibility(e.target.checked)}
        />
      )}
      label={<Typography className={classes.label} variant="button">Map</Typography>}
    />
  </ArrowTooltip>
);

const mapStateToProps = (state) => ({
  visible: state.map.visible,
});

const mapDispatchToProps = (dispatch) => ({
  toggleMapVisibility: (payload) => dispatch(toggleMapVisibility(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MapToggle));
