import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Toolbar from './Toolbar';
import TextSearch from './TextSearch';
import PlaceSearch from './PlaceSearch';
import Breadcrumbs from './Breadcrumbs';

const styles = (theme) => ({
  tableUi: {
    width: '100%',
    backgroundColor: '#000000',
    padding: 0,
    paddingBottom: theme.spacing.unit,
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  appLogoImg: {
    [theme.breakpoints.up('md')]: {
      height: 60,
    },
    [theme.breakpoints.down('sm')]: {
      height: 40,
    },
  },
  search: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing.unit,
  },
  leftContainer: {
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  left: { display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap' },
  right: { display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' },
  title: { marginLeft: 24, marginBottom: 6, color: 'white' },
});

const TableUI = ({ classes, width, clearParameters, mapExpand }) => (
  <div id="table-ui" className={classes.tableUi}>
    <div className={classes.logoContainer}>
      <Button onClick={(e) => clearParameters()}>
        <img className={classes.appLogoImg} src="CodeFinderWhite.png" alt="NFPA Logo" style={mapExpand ? { height: 40 } : {}} />
        {process.env.REACT_APP_NEC && !['xs', 'sm'].includes(width) ? <Typography className={classes.title} variant="h3">National Electrical Code<sup>®</sup></Typography> : null}
        {process.env.REACT_APP_NEC && ['xs', 'sm'].includes(width) ? <Typography className={classes.title} variant="h3">NEC<sup>®</sup></Typography> : null}
      </Button>
    </div>
    <Toolbar />
    <Grid container className={classes.search}>
      <Grid item xs={6}>
        <Grid container className={classes.leftContainer} style={mapExpand ? { flexDirection: 'column-reverse' } : {}}>
          <Grid item sm={12} md={mapExpand ? 12 : 6} lg={mapExpand ? 12 : 7} className={classes.left}>
            <Breadcrumbs search="text" side="left" />
          </Grid>
          <Grid item sm={12} md={mapExpand ? 12 : 6} lg={mapExpand ? 12 : 5} className={classes.left}>
            <TextSearch />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container>
          <Grid item sm={12} md={mapExpand ? 12 : 6} lg={mapExpand ? 12 : 5} className={classes.right}>
            <PlaceSearch />
          </Grid>
          <Grid item sm={12} md={mapExpand ? 12 : 6} lg={mapExpand ? 12 : 7} className={classes.right}>
            <Breadcrumbs search="place" side="right" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </div>
);

export default withStyles(styles)(withWidth()(TableUI));
