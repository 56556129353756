import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

// generator to create an arrow for tooltips
function arrowGenerator(color) {
  return {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.95em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${color} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.95em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${color} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.95em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${color} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.95em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${color}`,
      },
    },
  };
}

const styles = (theme) => ({
  tooltip: {
    fontSize: 10,
    backgroundColor: theme.palette.grey[700],
  },
  arrow: {
    position: 'absolute',
    fontSize: 6,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  popper: {
    opacity: 1,
    ...arrowGenerator(theme.palette.grey[700]),
  },
});

class ArrowTooltip extends React.Component {
  state = {
    arrowRef: null,
  };

  handleArrowRef = (node) => this.setState({ arrowRef: node });

  render() {
    const { classes, title, children, placement, adjust } = this.props;
    const { arrowRef } = this.state;
    return (
      <Tooltip
        placement={placement ? placement : 'bottom'}
        title={(
          <React.Fragment>
            {title}
            <span className={classes.arrow} ref={this.handleArrowRef} style={adjust ? { marginLeft: 32 } : {}} />
          </React.Fragment>
        )}
        classes={{ tooltip: classes.tooltip, popper: classes.popper }}
        PopperProps={{
          popperOptions: {
            modifiers: {
              arrow: {
                enabled: Boolean(arrowRef),
                element: arrowRef,
              },
            },
          },
        }}
      >
        {children}
      </Tooltip>
    );
  }
}

export default withStyles(styles)(ArrowTooltip);
