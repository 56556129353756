import React from 'react';
import './styles/app.css';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import SplitPane from 'react-split-pane';

// redux connection tools
import { connect } from 'react-redux';
import { Router } from 'react-router-dom';
import history from './history';

// containers
import TableContainer from './components/table';
import MapContainer from './components/map';
import Disclaimer from './components/Disclaimer';
import CodeChecker from './components/map/CodeChecker';

// actions
import { LOAD_CODE_DICTIONARY } from './reducers/dictionaries';
import { CARTO_API_CALL_REQUEST } from './reducers/loadCarto';
import { splitPaneChange, splitPaneChanged } from './reducers/splitPane';
import { updateSelection } from './reducers/cartoParams';

import './styles/table.css';
import './styles/spinner.css';

const styles = (theme) => ({
  root: {
    height: '100vh',
    overflowY: 'hidden',
  },
  table: {
    zIndex: 1000,
    [theme.breakpoints.down('xs')]: {
      boxShadow: '0px 2px 10px #888888',
    },
    [theme.breakpoints.up('sm')]: {
      boxShadow: '2px 0px 10px #888888',
    },
    maxHeight: '100vh',
  },
  map: {
    [theme.breakpoints.down('xs')]: {
      minHeight: 600,
    },
    [theme.breakpoints.up('sm')]: {
      height: '100vh',
    },
  },
});

class App extends React.Component {
  componentDidMount() {
    // initialize table results
    this.props.loadData();
    if (process.env.REACT_APP_NEC) {
      this.props.updateSelection({ nfpanumber: 'nfpanumber-70' });
    }
  }

  render() {
    const { classes, mapExpand, splitPaneChange, splitPaneChanged } = this.props;
    return (
      <Router history={history}>
        <Disclaimer />
        <CodeChecker />
        <Grid container className={classes.root}>
          {mapExpand ? (
            <SplitPane
              className="split-layout"
              split="vertical"
              minSize={0}
              defaultSize={document.documentElement.clientWidth / 2}
              allowResize
              onChange={(size) => splitPaneChange(size)}
              onDragFinished={() => splitPaneChanged()}
            >
              <div className={classes.table}>
                <TableContainer />
              </div>
              <div className={classes.map}>
                <MapContainer />
              </div>
            </SplitPane>
          ) : (
            <Grid item xs={12} className={classes.table}>
              <TableContainer />
            </Grid>
          )}
        </Grid>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  mapExpand: state.map.expand,
});

// attach the store state to the component props
const mapDispatchToProps = (dispatch) => ({
  loadData: () => {
    dispatch({ type: CARTO_API_CALL_REQUEST });
    dispatch({ type: LOAD_CODE_DICTIONARY });
  },
  updateSelection: (params) => dispatch(updateSelection(params)),
  splitPaneChange: (payload) => dispatch(splitPaneChange(payload)),
  splitPaneChanged: (payload) => dispatch(splitPaneChanged(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(App));
