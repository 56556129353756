import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Spinner from '@material-ui/core/CircularProgress';

const styles = (theme) => ({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.9)',
    zIndex: 3000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spinner: {
    margin: theme.spacing.unit,
  },
});

export default withStyles(styles)(({ classes }) => (
  <div className={classes.container}>
    <Spinner className={classes.spinner} />
  </div>
));
