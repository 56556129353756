import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Fuse from 'fuse.js';
import _ from 'lodash';

import Button from '@material-ui/core/Button';
import Close from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

import { updateSelection, updateTextSearch } from '../../../reducers/cartoParams';

import arrowList from '../../../tools/arrowList';
import debounce from '../../../tools/debounce';
import splitCartoResult from '../../../tools/splitCartoResult';

// import Breadcrumbs from './Breadcrumbs';
import SuggestedMatch from './SuggestedMatch';

const styles = (theme) => ({
  root: {
    backgroundColor: 'white',
    fontSize: '1em',
    borderRadius: '22px 0 0 22px',
    padding: theme.spacing.unit,
    borderRight: '1px solid #999',
  },
  focused: {
    borderBottom: '2px solid #D50032',
  },
  underline: {
    '&:after': {
      borderBottomColor: '#D50032',
    },
  },
  clear: {
    width: '100%',
    minHeight: 0,
    padding: 0,
    color: '#565a5c',
    fontSize: '1em',
  },
  results: {
    display: 'table',
    backgroundColor: 'white',
    maxHeight: '80vh',
    overflowY: 'auto',
    marginLeft: 15,
    color: 'black',
    zIndex: 1000,
    boxShadow: '-2px 2px 3px #444444',
  },
  textInput: {
    margin: 0,
    '&:hover': {
      backgroundColor: 'rgba(51,51,51,0.5)',
    },
  },
});

class TextSearch extends Component {
  constructor() {
    super();
    // using a debouncer in a React component- escaping the synthetic event
    this.callSuggestions = debounce(this.callSuggestions, 250);
  }

  componentDidUpdate() {
    // enable key navigation of result list
    arrowList('text-suggested-list', 'text-keyword-search');
  }

  callSuggestions(value) {
    this.props.requestTextSearch(value);
  }

  clearSuggestions() {
    // clear the input field, force the fuse search to clear
    document.getElementById('text-keyword-search').value = '';
    this.callSuggestions('');
  }

  fuse(data) {
    const opts = {
      shouldSort: true,
      findAllMatches: true,
      threshold: 0.25,
      location: 0,
      distance: 500,
      includeMatches: true,
      includeScore: true,
      keys: [{ name: 'agency', weight: 0.1 }],
    };

    const fuse = new Fuse(data, opts);
    const search = this.props.searchTerm.toUpperCase();
    const results = fuse.search(search);
    return results;
  }

  render() {
    const { classes, allPlaces, suggestions, updateSelection, searchTerm } = this.props;
    let suggestionsObject;

    if ((suggestions && suggestions.length > 0) || (suggestions && this.fuse(allPlaces).length > 0)) {
      // reduce Postgres search results into unique matches by match type
      suggestionsObject = suggestions.reduce((acc, match) => {
        const { type, value, row } = match;
        // create an index with which to group results
        const indexValues = { nfpanumber: 0, agency: 1 };
        // values for sorting results
        const index = indexValues[type];
        // create a match Object with which to direct results
        const matchObject = {
          type,
          value,
          index,
          row,
        };
        acc.push(matchObject);
        return acc;
      }, []);
      // create Fuse search results
      let fuseSuggestions = this.fuse(allPlaces);
      if (fuseSuggestions.length > 0) {
        // formate fuse results to merge with Postgres results
        fuseSuggestions = fuseSuggestions.reduce((acc, row) => {
          row.matches.map((match) => {
            const type = match.key;
            const value = splitCartoResult(match.value, 1);
            // create an index with which to sort results
            const indexValues = { nfpanumber: 0, agency: 1 };
            // values for sorting results
            const index = indexValues[type];
            const { score } = row;
            // create a match Object with which to direct results
            const matchObject = { type, value, index, score, row };
            // populate acc with unique matches
            if (acc.filter((item) => item.type === matchObject.type && item.value === matchObject.value).length === 0) {
              acc.push(matchObject);
            }
            return true;
          });
          return acc;
        }, []);
      }
      // merge suggestionsObject with Fuse results
      suggestionsObject = suggestionsObject.concat(fuseSuggestions);
    } else if (suggestions && suggestions.length === 0 && this.fuse(allPlaces).length === 0) {
      // return safe null values if there are no results
      suggestionsObject = [{
        type: 'try changing your search.',
        value: 'No results, ',
        index: 0,
      }];
    }
    return (
      <React.Fragment>
        <TextField
          id="text-keyword-search"
          placeholder={process.env.REACT_APP_NEC ? 'agencies' : 'codes, agencies'}
          autoComplete="off"
          fullWidth
          InputProps={{
            classes: {
              root: classes.root,
              focused: classes.focused,
              underline: classes.underline,
            },
            startAdornment: <InputAdornment position="start"><SearchIcon /><Typography variant="button">Search</Typography></InputAdornment>,
          }}
          InputLabelProps={{
            style: {
              color: 'white',
              width: '100%',
              textAlign: 'left',
              fontSize: '14px',
              fontWeight: 'normal',
            },
          }}
          className={classes.textInput}
          onChange={(e) => this.callSuggestions(e.target.value)}
          margin="normal"
        />
        {suggestionsObject ? (
          <div style={{ display: 'flex', height: 0 }}>
            <div className={classes.results}>
              <Button
                className={classes.clear}
                onClick={(e) => this.clearSuggestions()}
              >
                <Close /> Close Results
              </Button>
              {/* <Breadcrumbs /> */}
              <hr style={{ margin: '5px' }} />
              <ul id="text-suggested-list" style={{ padding: 0 }}>
                {/* show top 10 results */}
                {_.sortBy(suggestionsObject, ['index', 'value'])
                  .slice(0, 9)
                  .map((result) => (
                    <SuggestedMatch
                      key={`${result.type}-${result.value}`}
                      result={result}
                      searchTerm={searchTerm}
                      updateSelection={updateSelection}
                      clearSuggestions={(e) => this.clearSuggestions()}
                    />
                  ))}
              </ul>
            </div>
          </div>
        ) : ('')}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  searchTerm: state.cartoParams.textSearch,
  allPlaces: state.loadCarto.rawResults,
  suggestions: state.cartoParams.searchSuggestions,
});

const mapDispatchToProps = (dispatch) => ({
  updateSelection: (payload) => dispatch(updateSelection(payload)),
  requestTextSearch: (payload) => dispatch(updateTextSearch(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TextSearch));
