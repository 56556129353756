import React from 'react';
import { connect } from 'react-redux';
import { loadNewBreaks } from '../../reducers/map';

const getType = ({ country, state }) => {
  if (!country && !state) {
    return 'country';
  } if (Boolean(country) && !state) {
    return 'state';
  } if (state) {
    return 'county';
  }
};
class CodeChecker extends React.Component {
  componentDidMount() {
    if (this.props.nfpanumber && this.props.type) {
      this.props.loadNewBreaks(this.props.nfpanumber, this.props.type);
    }
  }

  shouldComponentUpdate(nextProps) {
    if ((nextProps.nfpanumber && nextProps.nfpanumber !== this.props.nfpanumber) || (nextProps.type && nextProps.type !== this.props.type)) {
      this.props.loadNewBreaks(nextProps.nfpanumber, nextProps.type);
      return false;
    }
    return false;
  }

  render() {
    return <div />;
  }
}
const getState = (state) => ({
  nfpanumber: state.cartoParams.where.nfpanumber,
  type: getType(state.cartoParams.where),
});
const mapDispatch = (dispatch) => ({ loadNewBreaks: (num, type) => dispatch(loadNewBreaks(num, type)) });
export default connect(getState, mapDispatch)(CodeChecker);
