import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import FeedbackIcon from '@material-ui/icons/InsertComment';
import Typography from '@material-ui/core/Typography';

import ArrowTooltip from '../../../ArrowTooltip';

const styles = {
  feedback: {
    display: 'inline',
  },
  button: {
    color: 'white',
    padding: 6,
    '&:hover': {
      backgroundColor: '#333',
    },
  },
};

const Feedback = ({ classes, collapse, handleClose }) => (
  <div className={classes.feedback}>
    {collapse ? (
      <Link href="https://www.nfpa.org/EnforcementUpdateForm" target="_blank" rel="noopener noreferrer">
        <MenuItem onClick={handleClose}>
          <Typography variant="button">Submit Codes and Standards</Typography>
        </MenuItem>
      </Link>
    ) : (
      <a href="https://www.nfpa.org/EnforcementUpdateForm" target="_blank" rel="noopener noreferrer">
        <ArrowTooltip title="Submit codes and standards enforced in your region, here" placement="bottom" adjust>
          <IconButton aria-label="feedback" className={collapse ? '' : classes.button}>
            <FeedbackIcon />
          </IconButton>
        </ArrowTooltip>
      </a>
    )}
  </div>
);

export default withStyles(styles)(Feedback);
