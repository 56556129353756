export default (payload) => {
  // if necessary, scroll to the proper element in the results list
  if (payload && payload.type && payload.value) {
    const target = document.getElementById(`${payload.type}-${payload.value.split(' ').join('')}`);
    if (target && target.offsetTop) {
      const results = document.getElementById('results');
      results.scrollTop = target.offsetTop - results.offsetTop;
    }
  }
};
