import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import Feedback from './Feedback';
import Help from './Help';
import Info from './Info';
import CommonCodes from './CommonCodes';
import MapToggle from './MapToggle';

const styles = (theme) => ({
  toolBar: {
    position: 'absolute',
    zIndex: 1200,
    top: 0,
    right: 0,
    height: 72,
    display: 'flex',
    alignItems: 'center',
  },
  small: {
    height: 52,
  },
  superSmall: {
    position: 'static',
    width: '100%',
    justifyContent: 'center',
  },
});

const TheThreeTools = ({ small, anchorEl, handleClick, handleClose }) => {
  const open = Boolean(anchorEl);
  if (small) {
    return (
      <React.Fragment>
        <IconButton
          aria-label="More"
          aria-owns={open ? 'long-menu' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          style={{ color: 'white' }}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: 200,
            },
          }}
        >
          {process.env.REACT_APP_NEC ? (
            <Info collapse handleClose={handleClose} />
          ) : (
            <>
              <Feedback collapse handleClose={handleClose} />
              <Help collapse handleClose={handleClose} />
              <Info collapse handleClose={handleClose} />
            </>
          )}
        </Menu>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {process.env.REACT_APP_NEC ? (
        <Info />
      ) : (
        <>
          <Feedback />
          <Help />
          <Info />
        </>
      )}
    </React.Fragment>
  );
};

class Toolbar extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, width, mapExpand, size } = this.props;
    const { anchorEl } = this.state;
    // identify if the toolbar should be small
    const small = ['sm', 'xs'].includes(width) || mapExpand;
    const extraSmall = width === 'xs';
    const superSmall = mapExpand && size < 530;
    return (
      <div className={`${classes.toolBar}${small || mapExpand ? ` ${classes.small}` : ''}${superSmall ? ` ${classes.superSmall}` : ''}`}>
        {process.env.REACT_APP_NEC ? null : <CommonCodes collapse={small} />}
        <TheThreeTools classes={classes} small={small} anchorEl={anchorEl} handleClick={this.handleClick} handleClose={this.handleClose} />
        {!extraSmall || mapExpand ? <MapToggle /> : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mapExpand: state.map.expand,
  size: state.splitPane.size,
});

export default withStyles(styles)(withWidth()(connect(mapStateToProps, null)(Toolbar)));
