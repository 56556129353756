import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import _ from 'lodash';

import Expand from '@material-ui/icons/ExpandMore';

import CodeRow from './CodeRow';
import CodeCount from './CodeCount';

import objectValues from '../../../../tools/objectValues';

const styles = {
  parentRow: {
    width: '100%',
    fontSize: '16px',
    lineHeight: '2em',
    margin: '0 5px 0 0',
    color: 'black',
  },
  childRow: {
    whiteSpace: 'normal',
    textAlign: 'left',
    marginLeft: '25px',
    fontSize: '0.9em',
    fontWeight: 'normal',
  },
  other: {
    color: '#444444',
    fontStyle: 'italic',
  },
  dropdownRight: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: -36,
  },
  open: {
    transform: 'rotate(90deg)',
  },
};

class ParentCode extends React.Component {
  state = {
    open: false,
  }

  handleChange = () => this.setState((state) => ({ open: !state.open }));

  render() {
    const { classes, code, noChildren, results } = this.props;
    const { open } = this.state;
    let codeCount;
    // calculate count of referenced NFPA codes and standards
    if (!noChildren) {
      codeCount = _.compact(objectValues(results).map((subCode) => subCode.parentid === code.deaid && !_.includes(['IBCO', 'NBCC', 'NFCC', 'IAPMO', 'ICC', 'ICC '], subCode.codetype))).length;
    }
    return (
      <div className={`${classes.parentRow} ${code.referencedtype === 'Other' ? classes.other : ''}`}>
        <CodeRow code={code} noParent={false} other={code.referencedtype === 'Other'} />
        {/* Only include a codecount and dropdown if there are children */}
        {!codeCount || codeCount === 0 ? '' : (
          <React.Fragment>
            <span className={classes.dropdownRight}>
              <button type="submit" onClick={this.handleChange}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CodeCount codeCount={codeCount} />
                  <Expand className={!open ? classes.open : ''} />
                </div>
              </button>
            </span>
            <Collapse in={open}>
              {objectValues(results).map((subCode) => (subCode.parentid === code.deaid ? (
                <div className={classes.childRow} key={subCode.deaid}>
                  <CodeRow code={subCode} noParent={false} />
                </div>
              ) : (<span key={subCode.deaid} />)))}
            </Collapse>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ParentCode);
