import { takeLatest, put } from 'redux-saga/effects';
import qs from 'querystring';
import { setBreaks, LOAD_NEW_BREAKS } from '../reducers/map';
import config from '../config/default';
import dbStringInput from '../tools/dbStringInput';
import { getCount } from '../tools/mapURL';
import { splitPaneChanged } from '../reducers/splitPane';

const { username, cartoKey } = config;
const getSQL = (number) => `WITH editions AS (
  SELECT DISTINCT number, editionyear
  FROM document
  INNER JOIN document_edition
    ON document.documentid = document_edition.documentid
  INNER JOIN document_edition_adoption
    ON document_edition.documenteditionid = document_edition_adoption.documenteditionid
  WHERE
     editionyear NOT IN ('Use Current Edition', 'Edition Not Specified')
     AND editionyear IS NOT NULL
     and number = ${dbStringInput(number)}
  ORDER BY editionyear
)

SELECT number, json_agg(editionyear) AS years
FROM editions
GROUP BY number
`;

const consolidateCodes = (years) => {
  console.log('years', years);
  // convert to array if a single string

  if (!Array.isArray(years)) {
    years = [years];
  }


  const inColors = ['#888888', 'rgb(224, 224, 224)', 'rgb(248, 212, 220)', 'rgb(226, 97, 127)', 'rgb(213, 0, 50)', 'rgb(145, 1, 35)'];
  const colors = [];
  const breaks = [];
  console.log('in years', years);
  // if there are more than 6 years, take top 5 and add `< FITH_EARLIEST_YEAR` as the 6th option
  if (years.length > 6) {
    years = years.slice(years.length - 5);
    years.unshift(`< ${years[0]}`);
  }

  console.log('years', years);
  for (let i = years.length - 1; i >= 0; i--) {
    const year = years[i];
    colors.unshift(inColors.pop());
    breaks.unshift(year);
  }
  console.log('out breaks', breaks);
  return { colors, breaks };
};

function* loadBreaks({ payload: { number, type } }) {
  const sql = getCount(number, type);
  if (!sql) {
    return;
  }
  const response = yield fetch(`https://${username}.carto.com/api/v2/sql?${qs.stringify({
    api_key: cartoKey,
    q: sql,
  })}`)
    .then((response) => response.json())
    .catch((error) => console.log('text search error: ', error.message));
  console.log('resp', response);
  if (!response.rows) return;
  console.log('ROWS', response.rows);
  const codes = consolidateCodes(response.rows[0].years);
  console.log('new codes', codes);
  yield put(setBreaks(codes));
  yield put(splitPaneChanged());
}

export default function* watchLoadBreaks() {
  yield takeLatest(LOAD_NEW_BREAKS, loadBreaks);
}
