import { takeLatest, call, put } from 'redux-saga/effects';
import setIn from 'set-in';
import qs from 'querystring';
import { LOAD_CODE_DICTIONARY, LOAD_CODE_DICTIONARY_SUCCESS } from '../reducers/dictionaries';
import config from '../config/default';
import objectValues from '../tools/objectValues';

const { tableSuffix } = config;

// request data from Carto
function fetchCartoCodes() {
  // build the SQL
  const sql = `
    SELECT *
    FROM (
      SELECT
        'country-'||countrynames.long country,
        'state-'||statenames.name state,
        'county-'||county.name county,
        'city-'||city.name city,
        'agency-'||org.name agency,
        ARRAY_AGG(doc.number) codes
      FROM nfpa_organization${tableSuffix} org
        LEFT OUTER JOIN nfpa_county${tableSuffix} county ON org.county_id = county.county_id
        LEFT OUTER JOIN nfpa_city${tableSuffix} city ON org.city_id = city.city_id
        INNER JOIN document_edition_adoption${tableSuffix} dea ON dea.agencyid = org.organization_id AND dea.status = 'Current Adoption'
        INNER JOIN document_edition${tableSuffix} de ON de.documenteditionid = dea.documenteditionid
        INNER JOIN document${tableSuffix} doc ON doc.documentid = de.documentid
        LEFT OUTER JOIN country_translation countrynames ON countrynames.short = org.country_code
        LEFT OUTER JOIN ne_50m_admin_1_states_provinces_lakes statenames ON statenames.postal = org.region_code AND statenames.iso_a2 = org.country_code
      GROUP BY countrynames.long, statenames.name, county.name, city.name, org.name
    ) results
    GROUP BY country, state, county, city, agency, codes
  `;
  return fetch(`https://${config.username}.carto.com/api/v2/sql?${qs.stringify({
    api_key: config.cartoKey,
    q: sql,
  })}`)
    .then((response) => response.json())
    .catch((error) => console.log('load dictionaries error: ', error.message));
}

// Function further structuring results into objects with a type, name, and row object
function makeRows(data, prop) {
  return Object.keys(data).filter((key) => key !== prop).map((key) => {
    const [type, name] = key.split(/-(.+)/);
    const nextRow = data[key];
    if (!name) return null;
    const flattenedProps = [...new Set([].concat(...data[key][prop]))];
    return { type, name, [prop]: flattenedProps, rows: makeRows(nextRow, prop) };
  });
}

// worker saga that fires the api call function when the watcher saga sees the action
function* loadCodeDictionary() {
  try {
    // fire loadCarto request, with specific requests as necessary
    const resultObj = yield call(fetchCartoCodes);
    const rawResults = resultObj.rows;

    // parse results into grouped object by reducing array to one object using keys and set-in package
    // Limit values to nest by to organization level, the 5th level
    const resultsAccumulator = rawResults.reduce((acc, row) => {
      // dictate number of nests based on whether countries are being loaded, or everything
      const nests = objectValues(row).slice(0, 5).filter((attribute) => attribute);
      const nestArray = [];
      nests.forEach((level) => {
        nestArray.push(level);
        acc = setIn(acc, [...nestArray, 'codes'], []);
      });
      return acc;
    }, {});
    let results = rawResults.reduce((acc, row) => {
      // dictate number of nests based on whether countries are being loaded, or everything
      const nests = objectValues(row).slice(0, 5).filter((attribute) => attribute);
      const nestArray = [];
      nests.forEach((level) => {
        nestArray.push(level);
        acc = setIn(acc, [...nestArray, 'codes', '-'], [...row.codes]);
      });
      return acc;
    }, resultsAccumulator);
    results = makeRows(results, 'codes');

    yield put({ type: LOAD_CODE_DICTIONARY_SUCCESS, payload: results });
  } catch (error) {
    // dispatch error action with error to store
    console.log('Error loading dictionaries: ', error);
  }
}

// watcher function to track actions to store, start loadCarto saga
export default function* watchCartoSaga() {
  yield takeLatest(LOAD_CODE_DICTIONARY, loadCodeDictionary);
}
