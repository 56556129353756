import qs from 'querystring';
import store from '../store';
import config from '../config/default';
import { updateSelection } from '../reducers/cartoParams';
import dbStringInput from './dbStringInput';

export default () => {
  if (!navigator.geolocation) {
    console.log('Browser doesn\'t support geolocation');
    return;
  }
  // If navigator is present, parse state from user's location
  navigator.geolocation.getCurrentPosition((position) => {
    const sql = `SELECT name, admin
    FROM codefinder.ne_50m_admin_1_states_provinces_lakes
    WHERE ST_Contains(the_geom, ST_GeometryFromText(${dbStringInput(`POINT(${position.coords.longitude} ${position.coords.latitude})`)},4326))`;
    fetch(`https://${config.username}.carto.com/api/v2/sql?${qs.stringify({
      api_key: config.cartoKey,
      q: sql,
    })}`)
      .then((response) => response.json()).then((data) => {
        if (data.rows) {
          const country = data.rows[0].admin;
          const state = data.rows[0].name;
          store.dispatch(updateSelection({ country: country ? `country-${country}` : null, state: state ? `state-${data.rows[0].name}` : null }));
        }
      })
      .catch((error) => console.log('update map bounds error: ', error.message));
  }, (error) => {
    console.log(`Geolocation failure: ${error}`);
  });
};
