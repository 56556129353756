import splitCartoResult from '../tools/splitCartoResult';

// define action types
export const UPDATE_SELECTION = 'UPDATE_SELECTION';
export const REMOVE_SELECTION = 'REMOVE_SELECTION';
export const UPDATE_TEXT_SEARCH = 'UPDATE_TEXT_SEARCH';
export const UPDATE_PLACE_SEARCH = 'UPDATE_PLACE_SEARCH';
export const STORE_SEARCH_SUGGESTIONS = 'STORE_SEARCH_SUGGESTIONS';
export const REMOVE_TEXT_SEARCH = 'REMOVE_TEXT_SEARCH';
export const REMOVE_PLACE_SEARCH = 'REMOVE_PLACE_SEARCH';

// actions
export const updateSelection = (payload) => ({ type: UPDATE_SELECTION, payload });
export const updatePlaceSearch = (payload) => ({ type: UPDATE_PLACE_SEARCH, payload });
export const updateTextSearch = (payload) => ({ type: UPDATE_TEXT_SEARCH, payload });
export const removeSelection = (payload) => ({ type: REMOVE_SELECTION, payload });

// app initial state
const initialState = {
  textSearch: null,
  placeSearch: null,
  searching: false,
  searchSuggestions: null,
  where: {
    country: null,
    state: null,
    county: null,
    city: null,
    agency: null,
    nfpanumber: null,
  },
};

// selectors
export const getTextSearch = (state) => state.cartoParams.textSearch;
export const getWhereSearch = (state) => state.cartoParams.where;

// reducers
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_SELECTION: {
      const { country, state: _state, county, city, agency, nfpanumber } = payload;
      if (nfpanumber) {
        return { ...state, where: { ...state.where, nfpanumber: splitCartoResult(nfpanumber, 1) } };
      }
      return {
        ...state,
        where: {
          ...state.where,
          country: splitCartoResult(country, 1),
          state: splitCartoResult(_state, 1),
          county: splitCartoResult(county, 1),
          city: splitCartoResult(city, 1),
          agency: splitCartoResult(agency, 1),
        },
      };
    }
    case REMOVE_SELECTION:
      switch (payload) {
        case 'country':
          return { ...state, where: { ...state.where, country: null, state: null, county: null, city: null, agency: null } };
        case 'state':
          return { ...state, where: { ...state.where, state: null, county: null, city: null, agency: null } };
        case 'county':
          return { ...state, where: { ...state.where, county: null, city: null, agency: null } };
        case 'city':
          return { ...state, where: { ...state.where, county: null, city: null, agency: null } };
        case 'agency':
          return { ...state, where: { ...state.where, agency: null } };
        case 'nfpanumber':
          return { ...state, where: { ...state.where, nfpanumber: null } };
        default:
          return state;
      }
    case UPDATE_TEXT_SEARCH:
      return { ...state, textSearch: payload, searching: true };
    case UPDATE_PLACE_SEARCH:
      return { ...state, placeSearch: payload, searching: true };
    case STORE_SEARCH_SUGGESTIONS:
      return { ...state, searchSuggestions: payload, searching: false };
    case REMOVE_TEXT_SEARCH:
      return { ...state, textSearch: null, searching: false, searchSuggestions: null };
    case REMOVE_PLACE_SEARCH:
      return { ...state, placeSearch: null, searching: false, searchSuggestions: null };
    default:
      return state;
  }
};
