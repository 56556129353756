// Dollar-Quoted String Constants: see 4.1.2.4 https://www.postgresql.org/docs/9.0/static/sql-syntax-lexical.html
function tagRegex(tag) {
  return new RegExp(`\\$${tag}\\$`);
}
function getBase(input) {
  const tagBase = 'cartodb';
  if (!input.toString().match(tagRegex(tagBase))) {
    return tagBase;
  }
  let i = 0;
  while (input.toString().match(tagRegex(tagBase + i))) {
    i++;
  }
  return tagBase + i;
}
export default (string) => {
  const rand = getBase(string);
  return `$${rand}$${string}$${rand}$`;
};
