import { takeLatest, select, put } from 'redux-saga/effects';
import qs from 'querystring';
import { UPDATE_TEXT_SEARCH, REMOVE_TEXT_SEARCH, getTextSearch, STORE_SEARCH_SUGGESTIONS } from '../reducers/cartoParams';
import config from '../config/default';
import dbStringInput from '../tools/dbStringInput';

const { tableSuffix, username, cartoKey } = config;

function* fetchSuggestions() {
  let textSearch = yield select(getTextSearch);
  // if textsearch is empty, cancel the saga and null the search values
  if (textSearch === '') {
    yield put({ type: REMOVE_TEXT_SEARCH });
    return;
  }
  // don't search for codes if in NEC version
  if (process.env.REACT_APP_NEC) {
    yield put({ type: STORE_SEARCH_SUGGESTIONS, payload: [] });
    return;
  }
  // get the search string and format it for Postgres
  textSearch = textSearch.replace(/\s+$/, '').replace(/nfpa/i, '').trim();
  if (textSearch === '') return;
  textSearch = textSearch.split(' ');
  textSearch = textSearch.join(':* %26 ');
  textSearch += ':*';

  // set up the sql to search nfpa code titles,aliases and numbers
  const sql = `
    WITH titles AS (
      WITH codes AS (
        SELECT DISTINCT
          de.title,
          doc.number,
          de.codenumberprefix codetype,
          CASE WHEN a.alias IS NULL THEN 'selectall' ELSE a.alias END AS alias
        FROM document_edition${tableSuffix} de
          INNER JOIN document_edition_adoption${tableSuffix} dea ON de.documenteditionid = dea.documenteditionid AND dea.status = 'Current Adoption'
          INNER JOIN nfpa_organization${tableSuffix} org ON dea.agencyid = org.organization_id
          LEFT OUTER JOIN nfpa_county${tableSuffix} county ON org.county_id = county.county_id
          LEFT OUTER JOIN nfpa_city${tableSuffix} city ON org.city_id = city.city_id
          INNER JOIN document${tableSuffix} doc ON doc.documentid = de.documentid
          FULL OUTER JOIN alias a ON doc.number = a.nfpanumber
          LEFT OUTER JOIN country_translation countrynames ON countrynames.short = org.country_code
          LEFT OUTER JOIN ne_50m_admin_1_states_provinces_lakes statenames ON statenames.postal = org.region_code AND statenames.iso_a2 = org.country_code
        WHERE 1=1
      )
      SELECT
        *,
        setweight(to_tsvector(coalesce(title, '')), 'C') || setweight(to_tsvector(coalesce(number, '')), 'B') || setweight(to_tsvector(coalesce(alias, '')), 'A') vector,
        to_tsquery('english', ${dbStringInput(textSearch)}) query
      FROM codes
    )
    SELECT title, number, codetype, alias, ts_rank_cd(vector, query, 2) rank
    FROM titles
    WHERE vector @@ query
    ORDER BY rank DESC
    LIMIT 10;
  `;
  const response = yield fetch(`https://${username}.carto.com/api/v2/sql?${qs.stringify({
    api_key: cartoKey,
    q: sql,
  })}`)
    .then((response) => response.json())
    .catch((error) => console.log('text search error: ', error.message));
  if (!response.rows) return;
  const matches = response.rows.map((row) => ({ type: 'nfpanumber', value: row.number, row: { title: row.title, codetype: row.codetype } }));
  yield put({ type: STORE_SEARCH_SUGGESTIONS, payload: matches });
}

export default function* watchTextSearch() {
  yield takeLatest(UPDATE_TEXT_SEARCH, fetchSuggestions);
}
