import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Fuse from 'fuse.js';
import _ from 'lodash';

import Button from '@material-ui/core/Button';
import Close from '@material-ui/icons/Close';
import PlaceIcon from '@material-ui/icons/Place';

import { updateSelection, updatePlaceSearch } from '../../../reducers/cartoParams';

import arrowList from '../../../tools/arrowList';
import debounce from '../../../tools/debounce';
import splitCartoResult from '../../../tools/splitCartoResult';

// import Breadcrumbs from './Breadcrumbs';
import SuggestedMatch from './SuggestedMatch';

const styles = (theme) => ({
  root: {
    backgroundColor: 'white',
    fontSize: '1em',
    borderRadius: '0 22px 22px 0',
    padding: theme.spacing.unit,
  },
  focused: {
    borderBottom: '2px solid #D50032',
  },
  underline: {
    '&:after': {
      borderBottomColor: '#D50032',
    },
  },
  clear: {
    width: '100%',
    minHeight: 0,
    padding: 0,
    color: '#565a5c',
    fontSize: '1em',
  },
  suggestionsWrapper: { display: 'flex', height: 0 },
  results: {
    display: 'table',
    backgroundColor: 'white',
    color: 'black',
    maxHeight: '80vh',
    overflowY: 'auto',
    marginRight: 15,
    zIndex: 1000,
    boxShadow: '-2px 2px 3px #444444',
  },
  textInput: {
    margin: 0,
    '&:hover': {
      backgroundColor: 'rgba(51,51,51,0.5)',
    },
  },
});

class TextSearch extends Component {
  constructor() {
    super();
    // using a debouncer in a React component- escaping the synthetic event
    this.callSuggestions = debounce(this.callSuggestions, 250);
  }

  componentDidUpdate() {
    // enable key navigation of result list
    arrowList('place-suggested-list', 'place-keyword-search');
  }

  callSuggestions(value) {
    this.props.requestTextSearch(value);
  }

  clearSuggestions() {
    // clear the input field, force the fuse search to clear
    document.getElementById('place-keyword-search').value = '';
    this.callSuggestions('');
  }

  fuse(data) {
    const opts = {
      shouldSort: true,
      findAllMatches: true,
      threshold: 0.25,
      location: 0,
      distance: 500,
      includeMatches: true,
      includeScore: true,
      keys: [
        { name: 'country', weight: 0.1 },
        { name: 'state', weight: 0.1 },
        { name: 'county', weight: 0.1 },
        { name: 'city', weight: 0.1 },
      ],
    };

    const fuse = new Fuse(data, opts);
    const search = this.props.searchTerm.toUpperCase();
    const results = fuse.search(search);
    return results;
  }

  render() {
    const { classes, allPlaces, updateSelection, searchTerm } = this.props;
    let suggestions = [];
    let suggestionsObject;
    if (searchTerm) suggestions = this.fuse(allPlaces);
    if (suggestions.length) {
      // create Fuse search results
      // formate fuse results to merge with Postgres results
      suggestionsObject = suggestions.reduce((acc, row) => {
        row.matches.map((match) => {
          const type = match.key;
          const value = splitCartoResult(match.value, 1);
          // create an index with which to sort results
          const indexValues = { nfpanumber: 0, city: 2, county: 3, state: 4, country: 5 };
          // values for sorting results
          const index = indexValues[type];
          const { score } = row;
          // create a match Object with which to direct results
          const matchObject = { type, value, index, score, row };
          // populate acc with unique matches
          if (acc.filter((item) => item.type === matchObject.type && item.value === matchObject.value).length === 0) {
            acc.push(matchObject);
          }
          return true;
        });
        return acc;
      }, []);
    } else if (searchTerm && searchTerm.length && suggestions.length === 0) {
      // return safe null values if there are no results
      suggestionsObject = [{
        type: 'try changing your search.',
        value: 'No results, ',
        index: 0,
      }];
    }
    return (
      <React.Fragment>
        <TextField
          id="place-keyword-search"
          placeholder="countries, regions, etc."
          autoComplete="off"
          fullWidth
          InputProps={{
            classes: {
              root: classes.root,
              focused: classes.focused,
              underline: classes.underline,
            },
            startAdornment: <InputAdornment position="start"><PlaceIcon /><Typography variant="button">In</Typography></InputAdornment>,
          }}
          InputLabelProps={{
            style: {
              color: 'white',
              width: '100%',
              textAlign: 'left',
              fontSize: '14px',
              fontWeight: 'normal',
            },
          }}
          className={classes.textInput}
          onChange={(e) => this.callSuggestions(e.target.value)}
          margin="normal"
        />
        {suggestionsObject ? (
          <div className={classes.suggestionsWrapper}>
            <div className={classes.results}>
              <Button
                className={classes.clear}
                onClick={(e) => this.clearSuggestions()}
              >
                <Close /> Close Results
              </Button>
              {/* <Breadcrumbs /> */}
              <hr style={{ margin: '5px' }} />
              <ul id="place-suggested-list" style={{ padding: 0 }}>
                {/* show top 10 results */}
                {_.sortBy(suggestionsObject, ['index', 'value'])
                  .slice(0, 9)
                  .map((result) => (
                    <SuggestedMatch
                      key={`${result.type}-${result.value}`}
                      result={result}
                      searchTerm={searchTerm}
                      updateSelection={updateSelection}
                      clearSuggestions={(e) => this.clearSuggestions()}
                    />
                  ))}
              </ul>
            </div>
          </div>
        ) : ('')}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  searchTerm: state.cartoParams.placeSearch,
  allPlaces: state.loadCarto.rawResults,
});

const mapDispatchToProps = (dispatch) => ({
  updateSelection: (payload) => dispatch(updateSelection(payload)),
  requestTextSearch: (payload) => dispatch(updatePlaceSearch(payload)),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TextSearch));
