export default {
  1: ['Fire Code'],
  101: ['LSC'],
  1192: ['RVs', 'RV\'s'],
  13: ['wet pipe system'],
  25: ['ITM'],
  '51B': ['Hot Work'],
  54: ['ANSI Z223.1', 'NFPA 54/ANSI Z223.1: National Fuel Gas Code'],
  58: ['LP Gas Code'],
  70: ['NEC'],
  '99B': ['Surgical Center', 'Ambulatory'],
};
