import React from 'react';

import Collapse from '@material-ui/core/Collapse';
import { withStyles } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';
import ExpandIcon from '@material-ui/icons/UnfoldLess';
import Typography from '@material-ui/core/Typography';

import ArrowTooltip from '../ArrowTooltip';
import CommonCodes from '../table/table-ui/Toolbar/CommonCodes';
import { hasEditions } from '../../config/default';

const styles = (theme) => ({
  legendContainer: {
    position: 'absolute',
    zIndex: 500,
    right: 25,
    bottom: 30,
    textAlign: 'right',
  },
  legend: {
    backgroundColor: 'white',
    minWidth: 200,
    maxWidth: 300,
    boxShadow: '2px 2px 10px #888888',
  },
  legendContent: {
    padding: 5,
    textAlign: 'center',
  },
  collapseLegend: {
    backgroundColor: 'white',
    bottom: -18,
    right: -10,
    '&:hover': {
      backgroundColor: '@d5d5d5',
    },
  },
  binWrapper: {
    paddingBottom: 5,
  },
  binContainer: {
    display: 'flex',
    padding: '4px 0 4px 16px',
  },
  legendColor: {
    height: 20,
    width: 60,
    backgroundColor: '#888888',
    marginRight: theme.spacing.unit,
  },
  legendGuide: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
  },
  legendTitle: {
    marginRight: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
  },
  editions: {
    height: 20,
    width: 60,
    backgroundColor: hasEditions,
    opacity: 0.3,
    marginRight: theme.spacing.unit,
  },
});

const LegendContent = ({ classes, codeTitle, nfpanumber, state }) => (
  <div>
    <h4>NFPA {nfpanumber}</h4>
    <h4>{codeTitle}</h4>
    <p className={classes.legendTitle}>Enforced Code Edition: of all the editions of this document incorporated in the jurisdiction, this is the most recent edition.</p>
  </div>
);

const LegendHolder = ({ classes, state }) => (
  <div>
    <h4>NFPA CodeFinder®</h4>
    <p>
      <strong>Select</strong> any location within the map to view active codes or <strong>search</strong> for a specific code or standard within the filter panel.
    </p>
    <CommonCodes legend />
  </div>
);

const Bins = ({ classes, nfpanumber, breaks, colors }) => {
  if (breaks.length === 1 && breaks[0] === '0') {
    return (
      <div className={classes.binWrapper} style={!nfpanumber ? { display: 'none' } : {}}>
        <div>
        No incorporation at the local level.
        </div>
      </div>
    );
  }
  return (
    <div className={classes.binWrapper} style={!nfpanumber ? { display: 'none' } : {}}>
      {[0, 1, 2, 3, 4, 5, 6].filter((bin) => bin < breaks.length).map((bin) => (
        <div key={`legend-bin-${bin}`} className={classes.binContainer}>
          <div className={classes.legendColor} style={{ backgroundColor: colors[bin] }} />
          <div>
            {breaks[bin]}
          </div>
        </div>
      ))}
    </div>
  );
};

const LegendGuide = ({ classes }) => (
  <div>
    <hr style={{ margin: '5px 0 5px 0' }} />
    {/* <div className={classes.legendGuide}>
      <svg height="30px" width="30px">
        <circle cx={15} cy={15} r={8} />
      </svg>
      <div>
        Major City (Population &#62; 250,000)
      </div>
    </div> */}
    <div className={classes.legendGuide}>
      <div className={classes.editions} />
      <div>
        Enforces NFPA Codes
      </div>
    </div>
    <hr style={{ margin: '5px 0 5px 0' }} />
  </div>
);


class Legend extends React.Component {
  state = {
    open: true,
  };

  handleChange = () => this.setState((state) => ({ open: !state.open }));

  render() {
    const { classes, nfpanumber, state, codeTitle, breaks, colors } = this.props;
    const { open } = this.state;
    return (
      <div className={classes.legendContainer}>
        <ArrowTooltip title={open ? 'Collapse Legend' : 'Expand Legend'} adjust>
          <Fab aria-label="add" classes={{ root: classes.collapseLegend }} size="small" onClick={this.handleChange}>
            <ExpandIcon />
          </Fab>
        </ArrowTooltip>
        <Collapse in={open}>
          <div className={classes.legend}>
            <div className={classes.legendContent}>
              { nfpanumber && codeTitle ? <LegendContent classes={classes} codeTitle={codeTitle} nfpanumber={nfpanumber} state={state} /> : <LegendHolder classes={classes} state={state} /> }
              <Bins classes={classes} nfpanumber={nfpanumber} breaks={breaks} colors={colors} />
              <LegendGuide classes={classes} />
              <p style={{ marginTop: '8px', fontSize: '10px' }}>
                This tool is made available subject to Important Notices and Legal Disclaimers,
                which can be viewed at <a href="https://www.nfpa.org/disclaimers" target="_blank" rel="noopener noreferrer">www.nfpa.org/disclaimers</a>.
              </p>
              <Typography variant="button" style={{ marginBottom: '8px' }}>
                <a href="https://www.nfpa.org/privacypolicy" target="_blank" rel="noopener noreferrer" style={{ color: '#337ab7' }}>
                  Read Our Privacy Policy
                </a>
              </Typography>
            </div>
          </div>
        </Collapse>
      </div>
    );
  }
}

export default withStyles(styles)(Legend);
