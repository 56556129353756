import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import ArrowTooltip from '../../../ArrowTooltip';

import { updateSelection } from '../../../../reducers/cartoParams';

import config from '../../../../config/default';

const styles = (theme) => ({
  commonCodes: {
    color: 'white',
    fontSize: '12px',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: '#333',
    },
  },
  commonCodesSmall: {
    color: 'white',
    padding: 6,
    '&:hover': {
      backgroundColor: '#333',
    },
  },
  legend: {
    backgroundColor: 'white',
    color: 'black',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  },
  menu: {
    maxWidth: 500,
  },
  menuItem: {
    fontSize: 12,
    padding: theme.spacing.unit,
  },
});

class CommonCodes extends Component {
  state = {
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = (e) => {
    this.setState({ anchorEl: null });
    const value = e.currentTarget.id;
    this.props.updateCodeSearch({ nfpanumber: value ? `nfpanumber-${value}` : null });
  };

  render() {
    const { anchorEl } = this.state;
    const { classes, selectedCode, collapse, legend } = this.props;
    return (
      <React.Fragment>
        {collapse ? (
          <ArrowTooltip title="Search popular codes" placement="bottom">
            <IconButton
              className={classes.commonCodesSmall}
              aria-owns={anchorEl ? 'simple-menu' : null}
              aria-haspopup="true"
              onClick={this.handleClick}
            >
              <SearchIcon />
            </IconButton>
          </ArrowTooltip>
        ) : (
          <ArrowTooltip title="Search popular codes" placement="bottom" adjust>
            <Button
              className={`${classes.commonCodes}${legend ? ` ${classes.legend}` : ''}`}
              aria-owns={anchorEl ? 'simple-menu' : null}
              aria-haspopup="true"
              onClick={this.handleClick}
            >
              Common Codes
            </Button>
          </ArrowTooltip>
        )}
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          className={classes.menu}
        >
          {config.commonCodes.map((commonCode) => (
            <MenuItem
              className={classes.menuItem}
              onClick={(e) => this.handleClose(e)}
              key={commonCode.id}
              id={commonCode.id}
              selected={selectedCode === commonCode.id}
            >
              <Typography variant="inherit" noWrap>{commonCode.text}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedCode: state.cartoParams.where.nfpanumber,
});

const mapDispatchToProps = (dispatch) => ({
  updateCodeSearch: (payload) => dispatch(updateSelection(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CommonCodes));
