// define action types
export const UPDATE_MAP_BOUNDS = 'UPDATE_MAP_BOUNDS';
export const UPDATE_MAP_BOUNDS_SUCCESS = 'UPDATE_MAP_BOUNDS_SUCCESS';
export const UPDATE_MAP_BOUNDS_ERROR = 'UPDATE_MAP_BOUNDS_ERROR';
export const TOGGLE_MAP_EXPAND = 'TOGGLE_MAP_EXPAND';
export const TOGGLE_MAP_VISIBILITY = 'TOGGLE_MAP_VISIBILITY';
export const LOAD_NEW_BREAKS = 'LOAD_NEW_BREAKS';
export const SET_BREAKS = 'SET_BREAKS';

// app initial state
const initialState = {
  error: null,
  bounds: null,
  expand: false,
  visible: true,
  breaks: ['< 2012', '2012', '2014', '2016', '2018', '2020'],
  // original scale
  // colors: ['#D1B8BD', '#D18F9E', '#D3516F', '#D50032', '#910123'],
  // IN USE grey - red scale
  colors: ['#888888', 'rgb(224, 224, 224)', 'rgb(248, 212, 220)', 'rgb(226, 97, 127)', 'rgb(213, 0, 50)', 'rgb(145, 1, 35)'],
  // rebalanced red scale
  // colors: ['#F9DAE1', '#EA7F98', '#D50032', '#920022', '#4F0013'],
};

// actions
export const toggleMapExpand = (payload) => ({ type: TOGGLE_MAP_EXPAND, payload });
export const toggleMapVisibility = (payload) => ({ type: TOGGLE_MAP_VISIBILITY, payload });
export const setBreaks = (payload) => ({ type: SET_BREAKS, payload });
export const loadNewBreaks = (number, type) => ({ type: LOAD_NEW_BREAKS, payload: { number, type } });
// selectors
export const getBreaksColors = (state) => ({ breaks: state.map.breaks, colors: state.map.colors });

// reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_BREAKS:
      return { ...state, breaks: action.payload.breaks, colors: action.payload.colors };
    case UPDATE_MAP_BOUNDS:
      return state;
    case UPDATE_MAP_BOUNDS_SUCCESS:
      return { ...state, bounds: action.bounds };
    case UPDATE_MAP_BOUNDS_ERROR:
      return { ...state, error: action.error };
    case TOGGLE_MAP_EXPAND:
      return { ...state, expand: action.payload };
    case TOGGLE_MAP_VISIBILITY: {
      let { expand } = state;
      if (!action.payload) expand = false;
      return { ...state, visible: action.payload, expand };
    }
    default:
      return state;
  }
};
