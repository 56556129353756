import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { CSSTransitionGroup } from 'react-transition-group';
import _ from 'lodash';

import Expand from '@material-ui/icons/ExpandMore';

import { updateSelection, removeSelection } from '../../../reducers/cartoParams';
import filterResults from '../../../tools/filterResults';

import Code from './Code';
import CodeCount from './Code/CodeCount';
import Spinner from '../../Spinner';

const styles = (theme) => ({
  tableWrapper: {
    fontSize: '18px',
    fontWeight: 'bold',
    paddingLeft: 32,
    backgroundColor: 'white',
  },
  tableHeading: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#FFFFFF',
    textAlign: 'left',
    padding: '5px 0 5px 10px',
    borderWidth: '0 0 1px 0',
    borderStyle: 'solid',
    borderColor: '#888888',
    cursor: 'pointer',
    position: 'relative',
    '-ms-transition': 'background-color 600ms',
    '-webkit-transition': 'background-color 600ms',
    transition: 'background-color 600ms',
    '&:hover': {
      backgroundColor: '#f4f4f4',
    },
    '&:focus': {
      backgroundColor: '#f4f4f4',
    },
  },
  open: {
    boxShadow: '5px 3px 5px 0 #888888',
  },
  countryItem: {
    zIndex: 100,
    '&:hover': { backgroundColor: '#f0f0f0' },
    '&:focus': { backgroundColor: '#f0f0f0' },
  },
  stateItem: {
    zIndex: 99,
    backgroundColor: '#f0f0f0',
    '&:hover': { backgroundColor: '#d9d9d9' },
    '&:focus': { backgroundColor: '#d9d9d9' },
  },
  countyItem: {
    zIndex: 98,
    backgroundColor: '#d9d9d9',
    '&:hover': { backgroundColor: '#bdbdbd' },
    '&:focus': { backgroundColor: '#bdbdbd' },
  },
  cityItem: {
    zIndex: 97,
    backgroundColor: '#d9d9d9',
    '&:hover': { backgroundColor: '#bdbdbd' },
    '&:focus': { backgroundColor: '#bdbdbd' },
  },
  agencyItem: {
    fontSize: '0.9em',
    fontWeight: 'normal',
    zIndex: 96,
    backgroundColor: '#bdbdbd',
    '&:hover': { backgroundColor: '#969696' },
    '&:focus': { backgroundColor: '#969696' },
  },
  expandContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  expandClosed: {
    transform: 'rotate(90deg)',
  },
  expandOpen: {
    transform: 'none',
  },
});

// Recursive component creating dropdowns based on the depth of nested objects delivered from Carto to the store
const Dropdown = ({
  classes,
  where,
  fullLocation = {},
  open = true,
  removeSelect,
  updateSelect,
  results,
  countDictionary,
  loadingCodes,
  loadingCountDictionary,
  codeDictionary,
  selectedCode,
}) => {
  // RENDER EMPTY RESULTS OR CODES WITH NO NESTED CHILDREN
  // return empty div if the dropdown is closed or if data isn't loaded yet
  if (!open || !countDictionary || (where.nfpanumber && !codeDictionary)) {
    return <div />;
  }

  // filter results by parameters
  results = filterResults(where, results, countDictionary, codeDictionary);

  // order results.  Prioritize agencies if they are present
  if (_.includes(results.map((result) => result.type), 'agency')) results = _.orderBy(results, ['type'], ['asc']);

  // no more rows nested in the object- this is a code, so display code.
  if (results[0] && !results[0].rows) {
    return (
      <CSSTransitionGroup
        transitionName="dropdown"
        transitionAppear
        transitionEnter={false}
        transitionLeave={false}
        transitionAppearTimeout={1000}
      >
        <Code results={results} selectedCode={selectedCode} />
      </CSSTransitionGroup>
    );
  }

  // if there are simply no results, then encourage the user to refine their search
  if (!results.length && (loadingCodes || loadingCountDictionary)) {
    return <Spinner />;
  }
  if (!results.length && !loadingCodes && !loadingCountDictionary) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div>
          {where.nfpanumber ? <p><strong>This code has no active adoptions</strong></p> : <span />}
          {!where.nfpanumber ? <p><strong>Your search returned no results</strong></p> : <span />}
          <p>Try selecting a different location or removing search filters to broaden your search.</p>
        </div>
      </div>
    );
  }

  // Function to identify numberof adopted NFPA codes and standards by the rendering location
  const codeCount = (key) => countDictionary[key];

  // RENDER DROPDOWNS WITH NESTED CHILDREN
  return (
    <CSSTransitionGroup
      transitionName="dropdown"
      transitionAppear
      transitionEnter={false}
      transitionLeave={false}
      transitionAppearTimeout={1000}
    >
      <div key="dropdown-wrapper" className={classes.tableWrapper} style={results[0].type === 'country' ? { paddingLeft: 0 } : {}}>
        {results.map(({ type, name, rows }) => (
          <div
            className={classes[`${type}Item`]}
            key={`${type}-${name}`}
            id={`${type}-${name.split(' ').join('')}`}
          >
            <ClickableDiv
              classes={classes}
              codeCount={codeCount(`${type}-${name}`)}
              removeSelect={removeSelect}
              updateSelect={updateSelect}
              fullLocation={{ ...fullLocation, [type]: `${type}-${name}` }}
              type={type}
              name={name}
              open={where[type] === name}
              where={where}
            />
            <Dropdown
              classes={classes}
              removeSelect={removeSelect}
              updateSelect={updateSelect}
              fullLocation={{ ...fullLocation, [type]: `${type}-${name}` }}
              open={where[type] === name}
              results={rows}
              where={where}
              countDictionary={countDictionary}
              loadingCountDictionary={loadingCountDictionary}
              loadingCodes={loadingCodes}
              // The codeDictionary is nested in the same way as the codes.  Send a subset of this dictionary to the next dropdown
              codeDictionary={
                where.nfpanumber
                  ? codeDictionary.filter(({ name: dictName, type: dictType }) => (dictName === name && dictType === type))[0].rows : {}
              }
            />
          </div>
        ))}
      </div>
    </CSSTransitionGroup>
  );
};

const handleClick = (props) => {
  const { type, fullLocation, open, removeSelect, updateSelect } = props;
  // if open, remove this selection, else add it
  if (open) {
    removeSelect(type);
  } else {
    updateSelect(fullLocation);
  }
};
const ClickableDiv = (props) => {
  const { classes, name, type, open, codeCount } = props;
  return (
    <button className={open ? `${classes.tableHeading} ${classes.open} ${classes[`${type}Item`]}` : `${classes.tableHeading} ${classes[`${type}Item`]}`} onClick={() => handleClick(props)} type="button">
      <div>
        {name !== 'United States of America' ? name : `${name} & American Territories`}
        {type === 'county' || type === 'city' ? <span style={{ fontStyle: 'italic', fontWeight: 'normal' }}> {type}</span> : <span />}
      </div>
      <div className={classes.expandContainer}>
        {codeCount ? <CodeCount codeCount={codeCount} /> : <span />}
        <span><Expand className={open ? classes.expandOpen : classes.expandClosed} /></span>
      </div>
    </button>
  );
};

const mapStateToProps = (state) => ({
  where: state.cartoParams.where,
  results: state.loadCarto.formattedResults,
  loadingCodes: state.loadCarto.fetching,
  loadingCountDictionary: state.dictionaries.loadingCountDictionary,
  countDictionary: state.dictionaries.countDictionary,
  codeDictionary: state.dictionaries.codeDictionary,
  selectedCode: state.cartoParams.where.nfpanumber,
});

const mapDispatchToProps = (dispatch) => ({
  removeSelect: (payload) => dispatch(removeSelection(payload)),
  updateSelect: (payload) => dispatch(updateSelection(payload)),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Dropdown));
