import { fork, all } from 'redux-saga/effects';

// import sagas
import codeCount from './codeCount';
import loadCarto from './loadCarto';
import loadDictionaries from './loadDictionaries';
import textSearch from './textSearch';
import updateMapBounds from './updateMapBounds';
import updateBreaks from './updateBreaks';

const sagas = [
  codeCount,
  loadCarto,
  loadDictionaries,
  textSearch,
  updateMapBounds,
  updateBreaks,
];

function* globalSagas() {
  const globalSagasForks = sagas.map((saga) => fork(saga));
  yield all([...globalSagasForks]);
}

export default globalSagas;
