const arrowList = (listId, inputId) => {
  // identify text input and list output
  const list = document.getElementById(listId);
  if (list) {
    const first = list.firstChild.firstChild;
    const maininput = document.getElementById(inputId);

    // listen for key events
    document.onkeydown = (e) => {
      switch (e.keyCode) {
        // up
        case 38:
          if (document.activeElement === maininput) {
            first.parentNode.parentNode.lastChild.firstChild.focus();
          } else if (document.activeElement === first) {
            maininput.focus();
          } else {
            document.activeElement.parentNode.previousSibling.firstChild.focus();
          }
          break;
        // down
        case 40:
          if (document.activeElement === maininput) {
            first.focus();
          } else if (document.activeElement.parentNode.nextSibling) {
            document.activeElement.parentNode.nextSibling.firstChild.focus();
          } else {
            first.focus();
          }
          break;
        default:
          break;
      }
    };
  }
};

export default arrowList;
