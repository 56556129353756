import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';

import { REMOVE_SELECTION } from '../../../reducers/cartoParams';

const styles = (theme) => ({
  breadcrumbs: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing.unit,
    },
  },
  crumb: {
    color: 'white',
    border: '0px solid #d50032',
    maxWidth: '100%',
    height: 'auto',
    fontSize: 11,
  },
  label: {
    whiteSpace: 'normal',
    display: 'block',
  },
  icon: {
    color: '#d50032',
    '&:hover': {
      color: '#cecece',
    },
  },
  left: {
    justifyContent: 'flex-end',
  },
  right: {
    justifyContent: 'flex-start',
  },
});

const Breadcrumbs = ({ classes, search, side, where, removeCrumb }) => {
  const searchParams = {
    text: process.env.REACT_APP_NEC ? ['agency'] : ['nfpanumber', 'agency'],
    place: ['country', 'state', 'county', 'city'],
  };
  return (
    <div className={`${classes.breadcrumbs} ${classes[side]}`}>
      {Object.keys(where)
        .filter((param) => where[param] && searchParams[search] && _.includes(searchParams[search], param))
        .map((param) => <Crumb key={param} classes={classes} type={param} value={where[param]} crumbClick={removeCrumb} />)}
    </div>
  );
};

class Crumb extends Component {
  handleClick = () => {
    // send action to handler with type of paramaters and value
    this.props.crumbClick({
      type: this.props.type,
      value: this.props.value,
    });
  }

  render() {
    const { classes, type, value } = this.props;
    const typeFormat = type.charAt(0).toUpperCase() + type.slice(1);
    const valueFormat = value.charAt(0).toUpperCase() + value.slice(1);
    return (
      <Chip
        classes={{
          outlined: classes.crumb,
          label: classes.label,
          deleteIcon: classes.icon,
        }}
        variant="outlined"
        onClick={this.handleClick}
        onDelete={this.handleClick}
        deleteIcon={<CloseIcon fontSize="small" />}
        label={`
          ${typeFormat === 'Nfpanumber' ? `NFPA Number: ${valueFormat}` : ''}
          ${typeFormat === 'State' ? `Region: ${valueFormat}` : ''}
          ${typeFormat !== 'State' && typeFormat !== 'Nfpanumber' ? `${typeFormat}: ${valueFormat}` : ''}
        `}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  where: state.cartoParams.where,
});

const mapDispatchToProps = (dispatch) => ({
  removeCrumb: (crumb) => dispatch({ type: REMOVE_SELECTION, payload: crumb.type }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Breadcrumbs));
