import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

const styles = {
  referencedCodes: {
    display: 'inline',
    margin: '0 0 0 10px',
  },
  referencedCodeList: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  referencedCode: {
    textDecoration: 'underline',
    color: '#002c5f',
  },
};

const ReferencedCodesDialog = ({ classes, code, referencedCodes, onClose, ...other }) => {
  const handleClose = () => {
    onClose();
  };

  const handleCodeClick = () => {
    onClose();
    return true;
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      {...other}
    >
      <DialogContent className={classes.referencedCodeList}>
        <h4 style={{ width: '100%' }}>{code} References:</h4>
        {referencedCodes.map((code, i, array) => (
          <a
            key={`${code}-reference`}
            className={classes.referencedCode}
            onClick={() => handleCodeClick()}
            href={`https://www.nfpa.org/${code}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span style={{ whiteSpace: 'pre' }}>
                NFPA {code}
              {/* include commas, save last item */}
              {array.length - 1 !== i ? <span>,{'\u00A0'}{'\u00A0'}</span> : <span />}
            </span>
          </a>
        ))}
      </DialogContent>
    </Dialog>
  );
};

class ReferencedCodes extends Component {
  state = { open: false };

  handleClickOpen = (e) => {
    this.setState({ open: true });
  };

  handleClose = (value) => {
    this.setState({ open: false });
  };

  render() {
    return (
      <div className={this.props.classes.referencedCodes}>
        <Button onClick={(e) => this.handleClickOpen(e)}>Show Referenced Codes or Standards</Button>
        <ReferencedCodesDialog
          classes={this.props.classes}
          code={this.props.code}
          referencedCodes={this.props.referencedCodes}
          open={this.state.open}
          onClose={this.handleClose}
        />
      </div>
    );
  }
}

export default withStyles(styles)(ReferencedCodes);
