import config from '../config/default';
import dbStringInput from './dbStringInput';

const { tableSuffix } = config;
export const boundsURL = (country, state, county, city, nfpanumber) => {
  if (process.env.REACT_APP_NEC) nfpanumber = 70;
  let target = 'country';
  if (state) {
    target = 'state';
  }
  if (county) {
    target = 'county';
  }
  if (city) {
    target = 'city';
  }
  const urlString = `
  SELECT envelope
    ${city ? ', city' : ''}
    ${county ? ', county' : ''}
    ${state ? ', state' : ''}
    ${country ? ', country' : ''}
    ${nfpanumber ? ', nfpanumber' : ''}
    FROM (
    SELECT
      ${city ? 'city.name city,' : ''}
      ${county ? 'county.name county,' : ''}
      ${state ? 'state.name state,' : ''}
      country.admin country,
      ${nfpanumber ? 'doc.number nfpanumber,' : ''}
      ST_AsGeoJSON(ST_Envelope(${target}.the_geom)) envelope
      FROM ne_50m_admin_0_countries_lakes AS country
      INNER JOIN nfpa_organization${tableSuffix} AS org ON country.postal = org.country_code
      ${state ? 'INNER JOIN ne_50m_admin_1_states_provinces_lakes AS state ON state.postal = org.region_code AND state.iso_a2 = org.country_code' : ''}
      ${county ? `INNER JOIN nfpa_county${tableSuffix} nfpacounties ON nfpacounties.county_id = org.county_id` : ''}
      ${county ? 'INNER JOIN uscounty AS county ON county.name = nfpacounties.name AND county._2017_stat = state.name' : ''}
      ${city ? `INNER JOIN nfpa_city${tableSuffix} city ON org.city_id = city.city_id` : ''}
      INNER JOIN document_edition_adoption${tableSuffix} dea ON dea.agencyid = org.organization_id AND dea.status = 'Current Adoption'
      INNER JOIN document_edition${tableSuffix} de ON de.documenteditionid = dea.documenteditionid
      INNER JOIN document${tableSuffix} doc ON doc.documentid = de.documentid
    ) results
    WHERE 1=1${nfpanumber ? ` AND nfpanumber=${dbStringInput(nfpanumber)}` : ''}
    ${country ? ` AND country=${dbStringInput(country)}` : ''}
    ${state ? ` AND state=${dbStringInput(state)}` : ''}
    ${county ? ` AND county=${dbStringInput(county)}` : ''}
    ${city ? ` AND city=${dbStringInput(city)}` : ''}
    GROUP BY envelope${country ? ', country' : ''}${state ? ', state' : ''}${county ? ', county' : ''}${city ? ', city' : ''}${nfpanumber ? ', nfpanumber' : ''}
  `;
  return urlString;
};

// SQL string that delivers all necessary code information to attach to map results
const codeInfoString = `
  , codes AS (
    WITH dea AS (
      SELECT
        dea.agencyid,
        dea.documenteditionid dea_deid
      FROM document_edition_adoption${tableSuffix} dea
      WHERE dea.status='Current Adoption'
    ),
    de AS (
      SELECT
        documenteditionid de_deid,
        codenumberprefix codetype,
        codenumbersuffix suffix,
        CASE
          WHEN editionyear = 'Use Current Edition' THEN 2018
          WHEN editionyear ~ '^\\d+(.\\d+)?$' THEN to_number(substring(editionyear FROM 1 FOR 4),'0000')
          ELSE 0 END edition,
        documentid de_did
      FROM document_edition${tableSuffix}
    ),
    doc AS (
      SELECT
        documentid doc_did,
        number nfpanumber,
        title
      FROM document${tableSuffix}
    )
    SELECT
      agencyid,
      edition,
      nfpanumber,
      title,
      de.codetype,
      suffix
    FROM dea
    INNER JOIN de ON dea.dea_deid = de_deid
    INNER JOIN doc ON de.de_did = doc.doc_did
    GROUP BY agencyid, edition, nfpanumber, title, codetype, suffix
  )
`;

export const layerUrl = (nfpanumber, type, visible) => {
  if (process.env.REACT_APP_NEC) nfpanumber = 70;
  const spatial = 'cartodb_id, the_geom, the_geom_webmercator, ';
  const codeInfo = 'nfpanumber, title, edition, codetype, suffix,';
  switch (type) {
    case 'country':
      return `
        WITH country AS (
          SELECT
          ${spatial}
          'country-'||admin country,
          postal postal
          FROM ne_50m_admin_0_countries_lakes
        ),
        org AS (
          SELECT
            country_code cid,
            organization_id aid
          FROM nfpa_organization${tableSuffix}
        ),
        dea AS (
          SELECT DISTINCT agencyid agency FROM document_edition_adoption${tableSuffix}
        )
        ${nfpanumber ? codeInfoString : ''}
        SELECT DISTINCT ON (country)
          ${spatial}
          ${nfpanumber ? codeInfo : '\'0\' nfpanumber, \'0\' title, 0 edition, \'0\' codetype, \'0\' suffix, '}
          country
        FROM country
        INNER JOIN org ON org.cid = country.postal ${!visible ? 'AND org.aid = 0' : ''}
        INNER JOIN dea ON dea.agency = org.aid
        ${nfpanumber ? `${process.env.REACT_APP_NEC ? 'INNER JOIN' : 'LEFT OUTER JOIN'} codes ON codes.agencyid = org.aid AND nfpanumber = ${dbStringInput(`${nfpanumber}`)}` : ''}
        ORDER BY country, nfpanumber, edition DESC
      `;
    case 'state':
      return `
        WITH country AS (
          SELECT
          'country-'||admin country,
          postal postal
          FROM ne_50m_admin_0_countries_lakes
        ),
        org AS (
          SELECT
            country_code cid,
            region_code rid,
            organization_id aid,
            'agency-'||"name" agency
          FROM nfpa_organization${tableSuffix}
        ),
        dea AS (
          SELECT DISTINCT agencyid agency FROM document_edition_adoption${tableSuffix}
        ),
        state AS (
          SELECT
            ${spatial}
            'state-'||name state,
            'country-'||admin stcountry,
            postal stpostal
          FROM ne_50m_admin_1_states_provinces_lakes st_geom
        )
        ${nfpanumber ? codeInfoString : ''}
        SELECT DISTINCT ON (country, state)
          ${spatial}
          ${nfpanumber ? codeInfo : '\'0\' nfpanumber, \'0\' title, 0 edition, \'0\' codetype, \'0\' suffix, '}
          country, state
        FROM country
        INNER JOIN org ON org.cid = country.postal ${!visible ? 'AND org.aid = 0' : ''}
        INNER JOIN state ON state.stcountry = country.country AND state.stpostal = org.rid
        INNER JOIN dea ON dea.agency = org.aid
        ${nfpanumber ? `
          INNER JOIN codes ON codes.agencyid = org.aid
          WHERE nfpanumber = ${dbStringInput(`${nfpanumber}`)}
        ` : ''}
        ORDER BY country, state, nfpanumber, edition DESC
      `;
    case 'county':
      return `
        WITH country AS (
          SELECT
          'country-'||admin country,
          postal postal
          FROM ne_50m_admin_0_countries_lakes
        ),
        org AS (
          SELECT
            country_code cid,
            region_code rid,
            county_id couid,
            organization_id aid,
            'agency-'||"name" agency
          FROM nfpa_organization${tableSuffix}
        ),
        dea AS (
          SELECT DISTINCT agencyid agency FROM document_edition_adoption${tableSuffix}
        ),
        state AS (
          SELECT
            'state-'||name state,
            'country-'||admin stcountry,
            postal stpostal
          FROM ne_50m_admin_1_states_provinces_lakes st_geom
        ),
        nfpa_county AS (
          SELECT
            county_id,
            'county-'||"name" county,
            'state-'||state_name ctstate
          FROM nfpa_county${tableSuffix} nfpa_ct
          INNER JOIN (
            SELECT
              "name" state_name,
              postal state_short
            FROM ne_50m_admin_1_states_provinces_lakes
          ) st ON st.state_short = nfpa_ct.state
        ),
        county AS (
          SELECT
            ${spatial}
            'county-'||"name" AS cty_name,
            'state-'||"_2017_stat" st_name
          FROM uscounty
        )
        ${nfpanumber ? codeInfoString : ''}
        SELECT DISTINCT ON (country, state, county)
          ${spatial}
          ${nfpanumber ? codeInfo : '\'0\' nfpanumber, \'0\' title, 0 edition, \'0\' codetype, \'0\' suffix, '}
          country, state, county
        FROM country
        INNER JOIN org ON org.cid = country.postal ${!visible ? 'AND org.aid = 0' : ''}
        INNER JOIN state ON state.stcountry = country.country AND state.stpostal = org.rid
        INNER JOIN nfpa_county ON nfpa_county.county_id = org.couid
        INNER JOIN county ON county.cty_name =nfpa_county.county AND county.st_name = nfpa_county.ctstate
        INNER JOIN dea ON dea.agency = org.aid
        ${nfpanumber ? `
          INNER JOIN codes ON codes.agencyid = org.aid
          WHERE nfpanumber = ${dbStringInput(`${nfpanumber}`)}
        ` : ''}
        ORDER BY country, state, county, nfpanumber, edition DESC
      `;
    default: return '';
  }
};

export const getCount = (nfpanumber, type) => {
  console.log('count', nfpanumber, type);
  if (process.env.REACT_APP_NEC) nfpanumber = 70;
  const spatial = 'cartodb_id, the_geom, the_geom_webmercator, ';
  const codeInfo = 'nfpanumber, title, edition, codetype, suffix,';
  switch (type) {
    case 'country':
      return `
        WITH country AS (
          SELECT
          ${spatial}
          'country-'||admin country,
          postal postal
          FROM ne_50m_admin_0_countries_lakes
        ),
        org AS (
          SELECT
            country_code cid,
            organization_id aid
          FROM nfpa_organization${tableSuffix}
        ),
        dea AS (
          SELECT DISTINCT agencyid agency FROM document_edition_adoption${tableSuffix}
        )
        ${nfpanumber ? codeInfoString : ''},
        allTheData as (
        SELECT DISTINCT ON (country)
          ${spatial}
          ${nfpanumber ? codeInfo : '\'0\' nfpanumber, \'0\' title, 0 edition, \'0\' codetype, \'0\' suffix, '}
          country
        FROM country
        INNER JOIN org ON org.cid = country.postal
        INNER JOIN dea ON dea.agency = org.aid
        ${nfpanumber ? `${process.env.REACT_APP_NEC ? 'INNER JOIN' : 'LEFT OUTER JOIN'} codes ON codes.agencyid = org.aid AND nfpanumber = ${dbStringInput(`${nfpanumber}`)}` : ''}
        ORDER BY country, nfpanumber, edition DESC),
        agged as (
          select distinct edition from allTheData  where edition is not null order by edition
        )
          select array_agg(edition::text) as years from agged

      `;
    case 'state':
      return `
        WITH country AS (
          SELECT
          'country-'||admin country,
          postal postal
          FROM ne_50m_admin_0_countries_lakes
        ),
        org AS (
          SELECT
            country_code cid,
            region_code rid,
            organization_id aid,
            'agency-'||"name" agency
          FROM nfpa_organization${tableSuffix}
        ),
        dea AS (
          SELECT DISTINCT agencyid agency FROM document_edition_adoption${tableSuffix}
        ),
        state AS (
          SELECT
            ${spatial}
            'state-'||name state,
            'country-'||admin stcountry,
            postal stpostal
          FROM ne_50m_admin_1_states_provinces_lakes st_geom
        )
        ${nfpanumber ? codeInfoString : ''},
        allTheData as (
        SELECT DISTINCT ON (country, state)
          ${spatial}
          ${nfpanumber ? codeInfo : '\'0\' nfpanumber, \'0\' title, 0 edition, \'0\' codetype, \'0\' suffix, '}
          country, state
        FROM country
        INNER JOIN org ON org.cid = country.postal
        INNER JOIN state ON state.stcountry = country.country AND state.stpostal = org.rid
        INNER JOIN dea ON dea.agency = org.aid
        ${nfpanumber ? `
          INNER JOIN codes ON codes.agencyid = org.aid
          WHERE nfpanumber = ${dbStringInput(`${nfpanumber}`)}
        ` : ''}
        ORDER BY country, state, nfpanumber, edition DESC),
        agged as (
          select distinct edition from allTheData  where edition is not null order by edition
        )
          select array_agg(edition::text) as years from agged
      `;
    case 'county':
      return `
        WITH country AS (
          SELECT
          'country-'||admin country,
          postal postal
          FROM ne_50m_admin_0_countries_lakes
        ),
        org AS (
          SELECT
            country_code cid,
            region_code rid,
            county_id couid,
            organization_id aid,
            'agency-'||"name" agency
          FROM nfpa_organization${tableSuffix}
        ),
        dea AS (
          SELECT DISTINCT agencyid agency FROM document_edition_adoption${tableSuffix}
        ),
        state AS (
          SELECT
            'state-'||name state,
            'country-'||admin stcountry,
            postal stpostal
          FROM ne_50m_admin_1_states_provinces_lakes st_geom
        ),
        nfpa_county AS (
          SELECT
            county_id,
            'county-'||"name" county,
            'state-'||state_name ctstate
          FROM nfpa_county${tableSuffix} nfpa_ct
          INNER JOIN (
            SELECT
              "name" state_name,
              postal state_short
            FROM ne_50m_admin_1_states_provinces_lakes
          ) st ON st.state_short = nfpa_ct.state
        ),
        county AS (
          SELECT
            ${spatial}
            'county-'||"name" AS cty_name,
            'state-'||"_2017_stat" st_name
          FROM uscounty
        )
        ${nfpanumber ? codeInfoString : ''},
        allTheData as (
        SELECT DISTINCT ON (country, state, county)
          ${spatial}
          ${nfpanumber ? codeInfo : '\'0\' nfpanumber, \'0\' title, 0 edition, \'0\' codetype, \'0\' suffix, '}
          country, state, county
        FROM country
        INNER JOIN org ON org.cid = country.postal
        INNER JOIN state ON state.stcountry = country.country AND state.stpostal = org.rid
        INNER JOIN nfpa_county ON nfpa_county.county_id = org.couid
        INNER JOIN county ON county.cty_name =nfpa_county.county AND county.st_name = nfpa_county.ctstate
        INNER JOIN dea ON dea.agency = org.aid
        ${nfpanumber ? `
          INNER JOIN codes ON codes.agencyid = org.aid
          WHERE nfpanumber = ${dbStringInput(`${nfpanumber}`)}
        ` : ''}
        ORDER BY country, state, county, nfpanumber, edition DESC),
        agged as (
          select distinct edition from allTheData where edition is not null order by edition
        )
          select array_agg(edition::text) as years from agged
      `;
    default: return '';
  }
};
// insert at 164 to limit city visibility
// nfpanumber ? ' AND org.ciid = 0' : ''
