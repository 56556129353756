export default [
  '2M',
  '6',
  '7',
  '8',
  '10L',
  '10R',
  '12B',
  '12CT',
  '21',
  '26',
  '44',
  '49',
  '57INA',
  '70C',
  '70L',
  '81',
  '89M',
  '92M',
  '182M',
  '205MT',
  '206M',
  '254',
  '263',
  '271',
  '292M',
  '311',
  '325',
  '393',
  '419',
  '421',
  '491',
  '492',
  '493',
  '501B',
  '501BM',
  '604',
  '656',
  '657',
  '702W',
  '1004',
  '1121L',
  '1232',
  '1461',
  '1922'
];
