import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dropdown from './table-results/Dropdown';
import ScrollToTop from './ScrollToTop';

const styles = (theme) => ({
  table: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: 'white',
    paddingBottom: theme.spacing.unit * 2,
  },
});

const Table = ({ error, classes }) => (
  <div id="results" className={classes.table}>
    {error ? (<p style={{ color: 'red' }}>There was an error with your Carto call!</p>) : ''}
    <Dropdown />
    <ScrollToTop />
  </div>
);

export default withStyles(styles)(Table);
