import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import ParentCode from './ParentCode';
import CodeRow from './CodeRow';

import objectValues from '../../../../tools/objectValues';

const styles = {
  tableWrapper: {
    fontSize: '18px',
    fontWeight: 'bold',
    paddingLeft: 32,
    backgroundColor: 'white',
    backgrounColor: 'white',
    '&:hover': { backgroundColor: 'white', color: 'black' },
    '&:focus': { backgroundColor: 'white', color: 'black' },
  },
};

const Code = ({ classes, results, selectedCode }) => {
  const orderedResults = _.orderBy(objectValues(results), ['referencedtype', 'docnumber']);
  return (
    <div className={classes.tableWrapper}>
      {orderedResults.map((code) => {
        if (!code.nfpanumber) return <div key={code.country} />;
        if (_.includes(['', 'NULL', 'Other', null], code.referencedtype)) {
          return (
            <ParentCode
              key={code.deaid}
              code={code}
              noChildren={code.nochildren}
              results={orderedResults}
            />
          );
        }
        if (code.deaid && objectValues(results).filter((parentCode) => parentCode.deaid === code.parentid).length === 0) {
          return <CodeRow key={code.deaid} code={code} noParent />;
        }
        if (selectedCode === code.docnumber && code.parentid > 0) {
          return <CodeRow key={code.deaid} code={code} noParent />;
        }
        return '';
      })}
    </div>
  );
};

export default withStyles(styles)(Code);
