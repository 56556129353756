import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import L from 'leaflet';
import carto from '@carto/carto.js';
import _ from 'lodash';

import { updateSelection } from '../../reducers/cartoParams';
import splitCartoResult from '../../tools/splitCartoResult';

// variables to track layer popups


class Layer extends Component {
  static contextTypes = {
    map: PropTypes.object,
  };

  constructor(props) {
    super(props);
    const { source, style } = props;
    const cartoSource = new carto.source.SQL(source);
    const cartoStyle = new carto.style.CartoCSS(style);
    // console.log('source', source);
    // console.log('style', style);
    this.lastContent = '';
    this.content = '';
    this.closed = 0;
    this.popup = L.popup({ autoPan: false });
    // create new layer using initial sources and styles
    this.layer = new carto.layer.Layer(cartoSource, cartoStyle, {
      featureClickColumns: this.props.columns,
    });
  }

  componentDidMount() {
    const { client, noInteraction } = this.props;
    client.addLayer(this.layer);
    client.getLeafletLayer().addTo(this.context.map);

    // fire selection/deselection action on click
    this.layer.on('featureClicked', (e) => {
      this.handleClick(e);
    });

    // Set Popup
    this.layer.on('featureOver', (e) => {
      const { popup } = this;
      this.closed = 0;
      if (noInteraction) return;

      // set popup content
      const { country, state, county, codetype, suffix, nfpanumber, title, edition } = e.data;

      // determine if code gets an 'NFPA' prefix
      const nfpaPrefix = _.includes(['IBCO', 'NBCC', 'NFCC', 'IAPMO', 'ICC', 'ICC '], codetype) ? '' : 'NFPA ';
      const nfpaSuffix = suffix && suffix !== 'NULL' && suffix !== '0' ? suffix : '';

      this.content = `
        <div style="border-left: 2px solid #d50032; background-color: #222222; width: 120%; padding: 5px 0 5px 10px;margin-top: 15px;">
          <h1>${nfpaPrefix}${nfpanumber && nfpanumber !== '0' ? nfpanumber : 'CodeFinder'}${nfpaSuffix}</h1>
        </div>
        ${title && title !== '0' ? (`<h3 style="font-weight: bold;">
        ${title}${edition && edition !== 0 ? `, ${edition}` : ''}</h3>`
  ) : ('<span/>')}
          <h3>`;
      if (country && country !== '0') this.content += `${country.split(/-(.+)/)[1]}<br>`;
      if (state && state !== '0') this.content += `${state.split(/-(.+)/)[1]} <em>state</em><br>`;
      if (county && county !== '0') this.content += `${county.split(/-(.+)/)[1]} <em>county</em><br>`;
      this.content += `
          </h3>
        `;

      // Only open new popup if mouse has gone over new feature
      if (this.content !== this.lastContent) {
        popup
          .setLatLng(e.latLng)
          .setContent(this.content)
          .openOn(this.context.map);
        this.lastContent = this.content;
        return;
      }
      popup
        .setLatLng(e.latLng);
    });

    this.layer.on('featureOut', (e) => {
      if (noInteraction) return;
      this.context.map.closePopup(this.popup);
      this.lastContent = null;
    });
  }

  shouldComponentUpdate(nextProps) {
    const reload = nextProps.source !== this.props.source
    || nextProps.nfpanumber !== this.props.nfpanumber
    || nextProps.style !== this.props.style;
    return reload;
  }

  componentDidUpdate() {
    // close all open popups
    this.context.map.closePopup();
  }

  handleClick(feature) {
    const { noInteraction, where, updateSelect, type } = this.props;
    if (noInteraction) return;
    const { country, state, county } = feature.data;
    const { country: where_country, state: where_state, county: where_county } = where;
    // only request new data when changing country, state, county from current location
    if (type === 'country' && splitCartoResult(country, 1) === where_country) return;
    if (type === 'state' && splitCartoResult(state, 1) === where_state && !where_county) return;
    if (type === 'county' && splitCartoResult(county, 1) === where_county) return;
    updateSelect({ country, state, county });
  }

  render() {
    const { style, source } = this.props;
    const layerStyle = this.layer.getStyle();
    const layerSource = this.layer.getSource();

    layerSource.setQuery(source);
    layerStyle.setContent(style);

    return null;
  }
}

const mapStateToProps = (state) => ({
  where: state.cartoParams.where,
});

const mapDispatchToProps = (dispatch) => ({
  updateSelect: (payload) => dispatch(updateSelection(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layer);
