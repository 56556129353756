// define action types
export const CARTO_API_CALL_REQUEST = 'CARTO_API_CALL_REQUEST';
export const FETCHING = 'FETCHING';
export const CARTO_API_CANCEL = 'CARTO_API_CANCEL';
export const CARTO_API_CALL_SUCCESS = 'CARTO_API_CALL_SUCCESS';
export const CARTO_API_CALL_ERROR = 'CARTO_API_CALL_ERROR';
export const FETCH_CODE_TITLE = 'FETCH_CODE_TITLE';
export const COMPLETE_INITIAL_LOAD = 'COMPLETE_INITIAL_LOAD';

// app initial state
const initialState = {
  initialLoad: true,
  fetching: false,
  rawResults: null,
  formattedResults: null,
  codeTitle: null,
  error: null,
};

// selectors
export const getInitialLoad = (state) => state.loadCarto.initialLoad;
export const getFormattedResults = (state) => state.loadCarto.formattedResults;
export const getRawResults = (state) => state.loadCarto.rawResults;

// reducer
export default (state = initialState, { type, payload, error }) => {
  switch (type) {
    case COMPLETE_INITIAL_LOAD:
      return { ...state, initialLoad: false };
    case CARTO_API_CALL_REQUEST:
    case FETCHING:
      return { ...state, fetching: true, error: null };
    case FETCH_CODE_TITLE:
      return { ...state, codeTitle: payload };
    case CARTO_API_CANCEL:
      return { ...state, fetching: false };
    case CARTO_API_CALL_SUCCESS:
      return {
        ...state,
        fetching: false,
        formattedResults: payload.results,
        rawResults: payload.rawResults,
      };
    case CARTO_API_CALL_ERROR:
      console.log('Carto Error:');
      console.log(error);
      return { ...state, fetching: false, filteredFormattedResults: null, error };
    default:
      return state;
  }
};
