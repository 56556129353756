import _ from 'lodash';
import splitCartoResult from './splitCartoResult';

export default (where, results, countDictionary, codeDictionary) => {
  // filter results by all parameters
  const newResults = results.reduce((acc, result) => {
    let { type, name } = result;
    const { nfpanumber } = result;

    // identify type and name for nfpa codes
    if (!type && !result.nfpanumber) return acc;
    if (!type && result.nfpanumber) {
      type = 'nfpanumber';
      name = splitCartoResult(result.nfpanumber, 1);
    }

    // right away eliminate any place not in the count dictionary (there are no code results for that record)
    // if (type !== 'nfpanumber' && !countDictionary[`${type}-${name}`]) return acc;

    // filter results by all where parameters
    // don't add result if it doesn't match the where parameter
    // if (type && where[type] && where[type] !== name) return acc;

    // filter place-based results by code by comparing the result to the code dictionary
    if (type && type !== 'nfpanumber' && where.nfpanumber) {
      const { codes } = codeDictionary.filter(({ name: dictName, type: dictType }) => (dictName === name && dictType === type))[0];
      if (!_.includes(codes, where.nfpanumber)) return acc;
    }
    // filter codes by code
    if (where.nfpanumber && nfpanumber) {
      if (type === 'nfpanumber' && name !== where.nfpanumber) return acc;
    }

    // otherwise, add it to the results object
    acc.push(result);
    return acc;
  }, []);

  return newResults;
};
