// define action types
export const LOAD_CODE_DICTIONARY = 'LOAD_CODE_DICTIONARY';
export const LOAD_CODE_DICTIONARY_SUCCESS = 'LOAD_CODE_DICTIONARY_SUCCESS';
export const LOAD_COUNT_DICTIONARY = 'LOAD_COUNT_DICTIONARY';
export const LOAD_COUNT_DICTIONARY_SUCCESS = 'LOAD_COUNT_DICTIONARY_SUCCESS';

// app initial state
const initialState = {
  loadingCountDictionary: false,
  countDictionary: null,
  loadingCodeDictionary: false,
  codeDictionary: null,
};

// selectors
export const getCountDictionary = (state) => state.dictionaries.countDictionary;

// reducers
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_CODE_DICTIONARY:
      return { ...state, loadingCodeDictionary: true };
    case LOAD_CODE_DICTIONARY_SUCCESS:
      return { ...state, loadingCodeDictionary: false, codeDictionary: payload };
    case LOAD_COUNT_DICTIONARY:
      return { ...state, loadingCountDictionary: true };
    case LOAD_COUNT_DICTIONARY_SUCCESS:
      return { ...state, loadingCountDictionary: false, countDictionary: payload };
    default:
      return state;
  }
};
