import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import store from '../store';

import userLocation from '../tools/userLocation';

const styles = (theme) => ({
  disclaimerWrapper: {
    position: 'absolute',
    zIndex: 5000,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  disclaimer: {
    backgroundColor: 'white',
    borderRadius: '5px',
    border: '1px solid #999999',
    padding: '16px',
    boxShadow: '2px 2px 6px #999999',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxHeight: '80%',
    overflow: 'auto',
  },
  disclaimerText: {
    maxWidth: '350px',
    maxHeight: '75vh',
    overflow: 'auto',
    padding: '8px 0',
  },
});

class Disclaimer extends Component {
  toggle = (geo) => {
    if (geo) {
      // attempt to get user's state based on IP address
      userLocation();
    }
    this.props.toggleDisclaimer();
  }

  render() {
    const { classes, disclaimer } = this.props;
    const initialLocation = store.getState().router;
    return (
      disclaimer ? (
        <div className={classes.disclaimerWrapper}>
          <div className={classes.disclaimer}>
            <h3>CodeFinder® Tool DISCLAIMER</h3>
            <div className={classes.disclaimerText}>
              <p>
                <strong>Important Notices and Disclaimers:</strong> By using this CodeFinder® tool (the “tool”),
                  you agree to the terms and conditions of this agreement.  Publication of this tool is for the
                  sole purpose of creating general public awareness of some of the jurisdictions where Authorities
                  Having Jurisdiction (“AHJs”) may require the use of NFPA codes and/or standards.  While we request
                  that jurisdictions report their use of or amendment to NFPA codes and standards, we are unable to
                  require this reporting, and therefore this tool is not a comprehensive or exhaustive list nor are
                  amendments to NFPA codes and/or standards reflected in this information.  In addition,
                  while the information reflected in this tool is updated from time to time, it may not reflect
                  current regulations, requirements, editions or AHJs.  In no event should a user rely on this tool
                  as a complete source of information about the local, state, federal or other legal or regulatory
                  requirements applicable in any jurisdiction whatsoever.
              </p>
              <p>
                <strong>IMPORTANT NOTE:</strong> The tool includes a color-coded map.  The map reflects only the most
                up-to-date edition of a particular code or standard incorporated by any AHJ in the state or country,
                but <strong><em>not necessarily</em></strong> the edition of the code or standard that controls the entire jurisdiction.
                For example, if one city in a state has incorporated the most recent edition of an NFPA standard,
                the color-coding for that <strong><em>state</em></strong> will reflect this most recent edition, even if it
                has not been incorporated state-wide.
              </p>
              <p>
                <strong>
                  This tool is designed to be informational and educational only and in making this information available,
                  NFPA® is not, and is not attempting to provide or render legal, regulatory, engineering or other professional
                  services or advice nor is NFPA undertaking to perform any duty owed by any person or entity to someone else.
                  Anyone using this tool should rely on his or her own independent judgment or, as appropriate, seek the advice
                  of a competent professional and/or the authority having jurisdiction.
                </strong>
              </p>
              <p>
                While every effort has been made to achieve a work of high quality,
                <strong>
                  use of this tool is at the users own risk;
                  it is provided AS IS and AS AVAILABLE without guarantee or warranty of any kind, express or implied
                  (including the warranties of merchantability and fitness for a particular purpose) and without representation
                  or warranty regarding the accuracy, completeness, usefulness, timeliness, reliability or appropriateness.
                  NFPA assumes no responsibility or liability in connection with the information or opinions contained in
                  or expressed by this tool, its use or output.
                </strong>
                The NFPA shall in no event be liable for any personal injury,
                property, or other damages of any nature whatsoever, whether special, indirect, consequential, or compensatory,
                directly or indirectly resulting from the publication, use of, or reliance on this tool.
              </p>
              <p>
                This tool is made available subject to Important Notices and Legal Disclaimers, which can be viewed
                at <a href="https://www.nfpa.org/disclaimers" target="_blank" rel="noopener noreferrer">www.nfpa.org/disclaimers</a>.
              </p>
            </div>
            <Divider style={{ margin: '8px 0' }} />
            <Typography variant="button" style={{ marginBottom: '8px' }}>
              <a href="https://www.nfpa.org/privacypolicy" target="_blank" rel="noopener noreferrer" style={{ color: '#337ab7' }}>
                Read Our Privacy Policy
              </a>
            </Typography>
            <Button
              variant="contained"
              onClick={() => this.toggle(false)}
            >
            Use CodeFinder®
            </Button>
            {/* Only ask for user location with https and no initial location set */}
            { window.location.protocol !== 'http:' && initialLocation && initialLocation.search.length === 0 ? (
              <Button
                variant="contained"
                onClick={() => this.toggle(true)}
              >
                Use CodeFinder® with My Location
              </Button>
            ) : <span />}
          </div>
        </div>
      ) : null
    );
  }
}

const mapStateToProps = (state) => ({
  disclaimer: state.disclaimer.disclaimer,
});

const mapDispatchToProps = (dispatch) => ({
  toggleDisclaimer: () => {
    dispatch({ type: 'TOGGLE_DISCLAIMER' });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Disclaimer));
