import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { toggleDisclaimer } from '../../../../reducers/disclaimer';

import ArrowTooltip from '../../../ArrowTooltip';

const styles = {
  button: {
    color: 'white',
    padding: 6,
    '&:hover': {
      backgroundColor: '#333',
    },
  },
};

const Help = ({ classes, toggleDisclaimer, collapse, handleClose }) => {
  if (collapse) {
    return (
      <MenuItem onClick={() => {
        toggleDisclaimer();
        handleClose();
      }}
      >
        <Typography variant="button">Application Information</Typography>
      </MenuItem>
    );
  }
  return (
    <ArrowTooltip title="Application Information" placement="bottom" adjust>
      <IconButton aria-label="help" className={classes.button} onClick={toggleDisclaimer}>
        <InfoIcon />
      </IconButton>
    </ArrowTooltip>
  );
};

const mapDispatchToProps = (dispatch) => ({
  toggleDisclaimer: () => dispatch(toggleDisclaimer()),
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(Help));
