import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  count: {
    fontSize: 10,
    fontWeight: 'normal',
    fontStyle: 'italic',
  },
};

const CodeCount = ({ classes, codeCount }) => {
  if (!codeCount) return <span />;
  return (
    <span className={classes.count}>
      {codeCount.toLocaleString()} NFPA Reference{codeCount !== 1 ? 's' : ''}
    </span>
  );
};

export default withStyles(styles)(CodeCount);
