import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Map } from 'react-leaflet';
import carto from '@carto/carto.js';

import Fab from '@material-ui/core/Fab';
import CollapseIcon from '@material-ui/icons/UnfoldLess';
import BaseMap from './BaseMap';
import Layer from './Layer';
import LabelLayer from './LabelLayer';
import Legend from './Legend';

import ArrowTooltip from '../ArrowTooltip';

import { toggleMapExpand } from '../../reducers/map';

import config from '../../config/default';
import '../../styles/map.css';
import { layerUrl } from '../../tools/mapURL';
import cssStyle from '../../tools/cssStyle';

const styles = (theme) => ({
  collapse: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    zIndex: 1200,
    color: 'white',
    backgroundColor: '#d50032',
    '&:hover': {
      backgroundColor: '#af012a',
    },
  },
});

class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.nativeMap = null;

    this.cartoClient = new carto.Client({ apiKey: config.cartoKey, username: config.username });
  }

  componentDidMount() {
    // set initial map bounds and zoom
    if (this.nativeMap) {
      this.nativeMap.fitBounds([
        [-32, -142],
        [57, -31],
      ]);
      this.nativeMap.setMinZoom(3);
    }
    // update map bounds
    const { bounds } = this.props;
    if (this.nativeMap && bounds) {
      try {
        // this.nativeMap.invalidateSize();
        this.nativeMap.fitBounds(bounds);
      } catch (err) {
        console.log(err);
      }
    }
  }
  //
  // shouldComponentUpdate(nextProps) {
  //   // only render map when bounds change in Redux state
  //   const { bounds, changed } = this.props;
  //   if (bounds !== nextProps.bounds || changed !== nextProps.changed) {
  //     return true;
  //   }
  //   return false;
  // }

  componentDidUpdate(prevProps) {
    // update map bounds
    const { bounds } = this.props;
    this.nativeMap.invalidateSize();
    if (this.nativeMap && bounds) {
      try {
        this.nativeMap.fitBounds(bounds);
      } catch (err) {
        console.log(err);
      }
    }
  }

  render() {
    const { classes, where, nfpanumber, codeTitle, mapExpand, toggleMapExpand, breaks, colors } = this.props;
    const { country, state } = where;
    const { polyStyle } = cssStyle({ nfpanumber, breaks, colors });
    const nfpaCountryUrl = layerUrl(nfpanumber, 'country', !country && !state);
    const nfpaStateUrl = layerUrl(nfpanumber, 'state', Boolean(country) && !state);
    const nfpaCountyUrl = layerUrl(nfpanumber, 'county', Boolean(state));
    if (!mapExpand) return null;
    return (
      <div className="map">
        <InteractionBuffer />
        <ArrowTooltip title="Collapse Map" adjust>
          <Fab
            className={classes.collapse}
            size="medium"
            onClick={() => toggleMapExpand(false)}
          >
            <CollapseIcon />
          </Fab>
        </ArrowTooltip>
        <Legend nfpanumber={nfpanumber} state={state} codeTitle={codeTitle} breaks={breaks} colors={colors} />
        <Map
          className="map"
          ref={(node) => {
            this.nativeMap = node && node.leafletElement;
          }}
        >
          <BaseMap />
          <Layer
            visible
            id="nfpa-country-layer"
            type="country"
            source={nfpaCountryUrl}
            style={polyStyle}
            client={this.cartoClient}
            columns={['country', 'nfpanumber', 'title', 'edition', 'codetype', 'suffix']}
          />
          <Layer
            visible={Boolean(country)}
            id="nfpa-state-layer"
            type="state"
            source={nfpaStateUrl}
            style={polyStyle}
            client={this.cartoClient}
            columns={['country', 'state', 'nfpanumber', 'title', 'edition', 'codetype', 'suffix']}
          />
          <Layer
            visible={Boolean(state)}
            id="nfpa-county-layer"
            type="county"
            source={nfpaCountyUrl}
            style={polyStyle}
            client={this.cartoClient}
            columns={['country', 'state', 'county', 'nfpanumber', 'title', 'edition', 'codetype', 'suffix']}
          />
          <LabelLayer />
        </Map>
      </div>
    );
  }
}

const InteractionBuffer = () => (
  <div>
    <div className="interaction-buffer" id="ib-1" />
  </div>
);

const mapStateToProps = (state) => ({
  mapExpand: state.map.expand,
  where: state.cartoParams.where,
  nfpanumber: state.cartoParams.where.nfpanumber,
  bounds: state.map.bounds,
  changed: state.splitPane.changed,
  codeTitle: state.loadCarto.codeTitle,
  breaks: state.map.breaks,
  colors: state.map.colors,
});

const mapDispatchToProps = (dispatch) => ({
  toggleMapExpand: (payload) => dispatch(toggleMapExpand(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MapContainer));
