// define action types
export const TOGGLE_DISCLAIMER = 'TOGGLE_DISCLAIMER';

// app initial state
const initialState = {
  disclaimer: true,
};

// actions
export const toggleDisclaimer = () => ({ type: TOGGLE_DISCLAIMER });

// reducers
export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_DISCLAIMER:
      return { ...state, disclaimer: !state.disclaimer };
    default:
      return state;
  }
};
