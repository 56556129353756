export default [
  {id: '1', text: 'NFPA 1 Fire Code'},
  {id: '13', text: 'NFPA 13 Standard for the Installation of Sprinkler Systems'},
  {id: '13D', text: 'NFPA 13D Standard for the Installation of Sprinkler Systems in One- and Two-Family Dwellings and Manufactured Homes'},
  {id: '13R', text: 'NFPA 13R Standard for the Installation of Sprinkler Systems in Low-Rise Residential Occupancies'},
  {id: '20', text: 'NFPA 20 Standard for the Installation of Stationary Pumps for Fire Protection'},
  {id: '25', text: 'NFPA 25 Standard for the Inspection, Testing, and Maintenance of Water-Based Fire Protection Systems'},
  {id: '30', text: 'NFPA 30 Flammable and Combustible Liquids Code'},
  {id: '54', text: 'NFPA 54 National Fuel Gas Code'},
  {id: '58', text: 'NFPA 58 Liquefied Petroleum Gas Code'},
  {id: '70', text: 'NFPA 70® National Electrical Code®'},
  {id: '70E', text: 'NFPA 70E® Standard for Electrical Safety in the Workplace®'},
  {id: '72', text: 'NFPA 72® National Fire Alarm and Signaling Code®'},
  {id: '99', text: 'NFPA 99 Health Care Facilities Code'},
  {id: '101', text: 'NFPA 101® Life Safety Code®'},
];