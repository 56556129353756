import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const styles = (theme) => ({
  container: {
    position: 'sticky',
    display: 'none',
    zIndex: 200,
    width: '100%',
    justifyContent: 'center',
    bottom: 8,
  },
});

// Button to scroll user to the top
class ScrollToTop extends React.Component {
  state = {
    intervalId: 0,
  };

  // only display the button once scrolling down a certain way
  componentDidMount() {
    const scrollTarget = document.getElementById('results');
    const scrollButton = document.getElementById('scroll-button');
    scrollTarget.onscroll = function () {
      if (scrollTarget.scrollTop > 100) scrollButton.style.display = 'flex';
      else scrollButton.style.display = 'none';
    };
  }

  // repeatign scroll action, clearInterval once at top
  scrollStep = () => {
    const scrollTarget = document.getElementById('results');
    if (scrollTarget.scrollTop === 0) {
      clearInterval(this.state.intervalId);
    }
    scrollTarget.scroll(0, scrollTarget.scrollTop - 50);
  }

  scrollToTop = () => {
    const intervalId = setInterval(() => this.scrollStep(), 20);
    this.setState({ intervalId });
  }

  render() {
    const { classes } = this.props;
    return (
      <div id="scroll-button" className={classes.container}>
        <Button
          variant="contained"
          onClick={() => this.scrollToTop()}
        >
          Scroll to Top
        </Button>
      </div>
    );
  }
}

export default withStyles(styles)(ScrollToTop);
