// define action types
const SPLIT_PANE_CHANGE = 'SPLIT_PANE_CHANGE';
const SPLIT_PANE_CHANGED = 'SPLIT_PANE_CHANGED';
const MOUSE_DOWN = 'MOUSE_DOWN';
const MOUSE_UP = 'MOUSE_UP';

// app initial state
const initialState = {
  size: document.documentElement.clientWidth / 2,
  changed: 0,
  mousedown: false,
};

export const splitPaneChange = (payload) => ({ type: SPLIT_PANE_CHANGE, payload });
export const splitPaneChanged = (payload) => ({ type: SPLIT_PANE_CHANGED, payload });

// reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case SPLIT_PANE_CHANGE:
      return { ...state, size: action.payload };
    case SPLIT_PANE_CHANGED: {
      const newChanged = state.changed + 1;
      return { ...state, changed: newChanged };
    }
    case MOUSE_DOWN:
      return { ...state, mousedown: true };
    case MOUSE_UP:
      return { ...state, mousedown: false };
    default:
      return state;
  }
};
