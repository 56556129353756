import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import DOMPurify from 'dompurify';

const styles = (theme) => ({
  item: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  resultItem: {
    height: '100%',
    padding: '3px 10px 3px 10px',
    textAlign: 'left',
    border: 'none',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#D50032',
      cursor: 'pointer',
      color: 'white',
      border: 'none',
    },
    '&:focus': {
      backgroundColor: '#D50032',
      cursor: 'pointer',
      color: 'white',
      border: 'none',
    },
  },
});

// suggested matches to text search.  Results are notated by type (e.g. 'country'), and matching text is made bold

class SuggestedMatch extends Component {
  handleSelect = () => {
    const { result: { type, value, row }, updateSelection, clearSuggestions } = this.props;
    if (type && value && row) {
      if (type === 'nfpanumber') updateSelection({ nfpanumber: `nfpanumber-${value}` });
      else {
        const levels = ['country', 'state', 'county', 'city', 'agency'];
        const params = { ...row.item };
        delete params.nfpanumber;
        levels.forEach((level) => {
          if (levels.indexOf(level) > levels.indexOf(type)) delete params[level];
        });
        // Base SQL search upon provided selection
        updateSelection(params);
      }
    }
    // clear the input field, force the fuse search to clear
    clearSuggestions();
  }

  render() {
    const { classes, result, searchTerm } = this.props;
    // set up regular expression to find searchterm and make it bold on display
    const searchExp = new RegExp(searchTerm, 'gmi');
    // format the value and title of the search results to emphasize text matches
    let { value } = result;
    if (value.search(searchExp) !== -1) value = value.replace(searchExp, (match) => `<span style="font-weight: bolder;">${match}</span>`);
    let description = '';
    if (result.row && result.row.title) description = result.row.title;
    if (description.length && description.search(searchExp) !== -1) {
      description = description.replace(searchExp, (match) => `<span style="font-weight: bolder;">${match}</span>`);
    }
    return (
      <li className={classes.item}>
        <button type="submit" className={classes.resultItem} onClick={(e) => this.handleSelect()}>
          {result.type === 'nfpanumber' ? (
            <span>
              <strong>{_.includes(['IBCO', 'NBCC', 'NFCC', 'IAPMO', 'ICC', 'ICC '], result.row.codetype) ? '' : 'NFPA '}
                <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }} />
              </strong>
              {' - '}
              <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} />
            </span>
          ) : (
            <span>
              <span><span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }} /> </span>
              <span style={{ fontStyle: 'italic', fontWeight: 'normal' }}>
                {result.type === 'state' ? 'region' : result.type}
              </span>
            </span>
          )}
        </button>
      </li>
    );
  }
}

export default withStyles(styles)(SuggestedMatch);
