import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Map } from 'react-leaflet';
import carto from '@carto/carto.js';

import ButtonBase from '@material-ui/core/ButtonBase';
import BaseMap from './BaseMap';
import Layer from './Layer';
import ArrowTooltip from '../ArrowTooltip';

import { toggleMapExpand } from '../../reducers/map';
import config from '../../config/default';
import '../../styles/map.css';
import { layerUrl } from '../../tools/mapURL';
import cssStyle from '../../tools/cssStyle';

const styles = (theme) => ({
  map: {
    height: '100%',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  button: {
    borderRadius: 0,
    padding: 0,
    height: '100%',
    width: '100%',
    display: 'inline',
    '&:hover': {
      filter: 'brightness(90%)',
      transition: 'filter 200ms',
    },
  },
});

class InsetMapContainer extends Component {
  constructor(props) {
    super(props);
    this.nativeMap = null;

    this.cartoClient = new carto.Client({ apiKey: config.cartoKey, username: config.username });
  }

  componentDidMount() {
    // set initial map bounds and zoom
    const { bounds } = this.props;
    this.nativeMap.invalidateSize();
    if (this.nativeMap && bounds) {
      try {
        this.nativeMap.fitBounds(bounds);
      } catch (err) {
        console.log(err);
      }
    }
    this.nativeMap.setMinZoom(2);
  }

  // shouldComponentUpdate(nextProps) {
  //   // only render map when bounds change in Redux state
  //   const { bounds } = this.props;
  //   if (bounds !== nextProps.bounds) {
  //     return true;
  //   }
  //   return false;
  // }

  componentDidUpdate(prevProps) {
    // update map bounds
    const { bounds } = this.props;
    this.nativeMap.invalidateSize();
    if (this.nativeMap && bounds) {
      try {
        this.nativeMap.fitBounds(bounds);
      } catch (err) {
        console.log(err);
      }
    }
  }

  render() {
    const { classes, where, nfpanumber, toggleMapExpand, breaks, colors } = this.props;
    const { polyStyle } = cssStyle({ nfpanumber, breaks, colors });
    const { country, state } = where;
    const nfpaCountryUrl = layerUrl(nfpanumber, 'country', true);
    const nfpaStateUrl = layerUrl(nfpanumber, 'state', Boolean(country));
    const nfpaCountyUrl = layerUrl(nfpanumber, 'county', Boolean(state));
    return (
      <ArrowTooltip title="Expand Map">
        <ButtonBase classes={{ root: classes.button }} onClick={() => toggleMapExpand(true)}>
          <Map
            className={classes.map}
            ref={(node) => {
              this.nativeMap = node && node.leafletElement;
            }}
            boxZoom={false}
            doubleClickZoom={false}
            dragging={false}
            keyboard={false}
            scrollWheelZoom={false}
            tap={false}
            touchZoom={false}
            zoomControl={false}
          >
            <BaseMap />
            <Layer
              id="nfpa-country-layer"
              type="static"
              noInteraction
              source={nfpaCountryUrl}
              style={polyStyle}
              client={this.cartoClient}
            />
            <Layer
              id="nfpa-state-layer"
              type="static"
              noInteraction
              source={nfpaStateUrl}
              style={polyStyle}
              client={this.cartoClient}
            />
            <Layer
              id="nfpa-county-layer"
              type="static"
              noInteraction
              source={nfpaCountyUrl}
              style={polyStyle}
              client={this.cartoClient}
            />
          </Map>
        </ButtonBase>
      </ArrowTooltip>
    );
  }
}

const mapStateToProps = (state) => ({
  where: state.cartoParams.where,
  nfpanumber: state.cartoParams.where.nfpanumber,
  bounds: state.map.bounds,
  breaks: state.map.breaks,
  colors: state.map.colors,
});

const mapDispatchToProps = (dispatch) => ({
  toggleMapExpand: (payload) => dispatch(toggleMapExpand(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(InsetMapContainer));
