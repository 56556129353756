import commonCodes from './commonCodes';
import searchLookup from './searchLookup';
import withdrawnCodes from './withdrawnCodes';

export const hasEditions = '#4A7729';

export default {
  username: 'codefinder',
  cartoKey: 'cB08zKWimJKFnc0fPTAtKA',
  tableSuffix: '',
  bounds: {
    country: {
      Bolivia: [[-22.89169921875, -69.645703125], [-9.71044921875001, -57.4956542968749]],
      Brazil: [[-33.7421875, -74.00205078125], [5.25795898437498, -34.80546875]],
      Chile: [[-55.89169921875, -109.434130859375], [-17.5060546875, -66.435791015625]],
      Colombia: [[-4.23593750000001, -79.025439453125], [12.434375, -66.876025390625]],
      'Costa Rica': [[8.07065429687503, -85.9080078125], [11.189453125, -82.5635742187499]],
      'Dominican Republic': [[17.635595703125, -72.000390625], [19.91396484375, -68.33916015625]],
      Ecuador: [[-4.99062500000001, -91.654150390625], [1.45537109374996, -75.249609375]],
      'El Salvador': [[13.164013671875, -90.105908203125], [14.431103515625, -87.71533203125]],
      Mexico: [[14.54541015625, -118.4013671875], [32.71533203125, -86.6962890625]],
      Nicaragua: [[10.7353515625001, -87.6701660156249], [15.008056640625, -83.15751953125]],
      Panama: [[7.22006835937503, -83.02734375], [9.59785156250004, -77.19599609375]],
      Peru: [[-18.34560546875, -81.33662109375], [-0.0417480468749574, -68.68525390625]],
      'Saudi Arabia': [[16.37177734375, 34.6162109375001], [32.12451171875, 55.6410156250001]],
      'United Arab Emirates': [[22.621484375, 51.5683593749999], [26.0681640625, 56.3879882812502]],
      'United States of America': [[25.173598, -125.974207], [48.705584, -60.500217]],
    },
    state: {
      Alaska: [[51.6036760559577, -178.194518439938], [71.4076868211864, -130.01407280012]],
      'District of Columbia': [[38.8065259095821, -77.1220529601834], [39.0117834857714, -76.9312424060296]],
      'Rhode Island': [[41.3309095508015, -71.8423530517476], [42.0168629461092, -71.2320515147946]],
      Vermont: [[42.7303151217627, -73.4261311883358], [45.0075613023827, -71.5102253535311]],
      Connecticut: [[40.9918604656022, -73.7230147445829], [42.05556778157, -71.7957929913777]],
      Delaware: [[38.454864519214, -75.7847201454964], [39.8434375702309, -75.0358810056022]],
      Arizona: [[31.3242099503262, -114.835954545339], [37.0041527939519, -109.046670266878]],
      'North Dakota': [[45.9427725196989, -104.033927155735], [48.9931815716096, -96.5564012357246]],
      'New Jersey': [[38.9411413932328, -75.5242452820444], [41.3572987119644, -73.9100789607032]],
      'New Mexico': [[31.3278793840599, -109.047807351882], [37.0008459090602, -103.000294914157]],
      Tennessee: [[34.9880955881393, -90.2937600056129], [36.6930089832547, -81.6588902914532]],
      Kentucky: [[36.4996825598642, -89.5600380537956], [39.1289087343799, -81.9652770218909]],
      Utah: [[37.0008459090602, -114.04259330902], [42.001108550977, -109.0463901155]],
      Colorado: [[37.0008459090602, -109.046670266878], [41.0008582686798, -102.012783279807]],
      Alabama: [[30.2309164515891, -88.4861903565815], [35.0249986657189, -84.9215332381787]],
      Arkansas: [[33.0119956592414, -94.6183799215606], [36.5008690833469, -89.6889076876177]],
      California: [[32.5333652698893, -124.371653761636], [42.000767974792, -114.125018238922]],
      Florida: [[24.5423493400563, -87.6074378816535], [31.0016733169286, -80.0413072219348]],
      Georgia: [[30.3713546893683, -85.6236035774606], [35.0014769363059, -80.8723460719958]],
      Hawaii: [[18.9639091858494, -177.390484672083], [28.2211763846893, -154.804187576354]],
      Nevada: [[34.9911168284889, -120.000808868339], [42.001108550977, -114.040269700533]],
      Wyoming: [[41.0008582686798, -111.051433526947], [45.0011343001845, -104.021655426752]],
      Iowa: [[40.3794495535687, -96.6268345880208], [43.5024123191846, -90.1494326075292]],
      Idaho: [[42.0005592345498, -117.201701095546], [48.9930826946527, -111.050296441943]],
      Illinois: [[36.9921117778676, -91.5016518973935], [42.5129835705034, -87.5176795774483]],
      Indiana: [[37.7880273355731, -88.0953187596397], [41.7605866954936, -84.7896203913498]],
      Kansas: [[37.0008459090602, -102.02448921287], [40.0014868926662, -94.6180668111971]],
      Louisiana: [[28.9813314429838, -94.04330055392], [33.0159177785317, -88.812583191298]],
      Massachusetts: [[41.2494678973041, -73.507287197291], [42.8815639068293, -69.933841016053]],
      Maryland: [[37.9539648419032, -79.4879807848114], [39.7227966964881, -75.0376607908264]],
      Minnesota: [[43.5011708640592, -97.2257378091093], [49.3696720644872, -89.5775479607347]],
      Maine: [[43.0700343730032, -71.0845490677615], [47.4629947456595, -66.9870220559845]],
      Missouri: [[35.992740401854, -95.7650285248721], [40.6246534203444, -89.1224855468699]],
      Michigan: [[41.7014344672796, -90.4125881351459], [48.175927734375, -82.4172314415557]],
      Mississippi: [[30.1926290966129, -91.6564492665798], [35.001114387464, -88.0847718842373]],
      Montana: [[44.3966225583742, -116.049010011535], [48.9931376262955, -104.004780426108]],
      'North Carolina': [[33.8766636609759, -84.3246899536922], [36.6105785601883, -75.4564706212559]],
      Nebraska: [[40.0011243438242, -104.028862458276], [43.0014027785883, -95.3466801202415]],
      'New Hampshire': [[42.7024867515606, -72.5528059597086], [45.294007846513, -70.733096417636]],
      'New York': [[40.5187122541937, -79.7630029072081], [45.0054189683167, -71.9032063255534]],
      Oregon: [[42.0005592345498, -124.539629231911], [46.2254507531385, -116.477042864778]],
      Ohio: [[38.4149731602705, -84.8217114570271], [41.9895677093389, -80.5190806776603]],
      Oklahoma: [[33.6484336718008, -103.00083873742], [37.0008459090602, -94.4391379713637]],
      Pennsylvania: [[39.7223572433464, -80.5202781874717], [42.2756852765805, -74.6990511587689]],
      'South Carolina': [[32.0295652018428, -83.3555749264891], [35.2109312899991, -78.564272253481]],
      'South Dakota': [[42.511258716922, -104.03721206797], [45.943058164241, -96.4536186390304]],
      Texas: [[25.8705195567376, -106.668261973024], [36.5012316321889, -93.5305905734241]],
      Virginia: [[36.5505053157092, -83.6675207391701], [39.4518518619337, -75.2257906808155]],
      'West Virginia': [[37.2080700380589, -82.6195458456774], [40.6469556672889, -77.7266416062935]],
      Washington: [[45.5910671840167, -124.709977749152], [48.9930826946527, -116.896478915934]],
      Wisconsin: [[42.4971126758939, -92.8965201146659], [47.071142578125, -86.856591796875]],
    },
  },
  commonCodes,
  searchLookup,
  withdrawnCodes,
};
