import { takeLatest, call, select, put, take } from 'redux-saga/effects';
import setIn from 'set-in';
import _ from 'lodash';
import qs from 'querystring';

import {
  COMPLETE_INITIAL_LOAD,
  CARTO_API_CALL_REQUEST,
  FETCHING,
  CARTO_API_CANCEL,
  CARTO_API_CALL_ERROR,
  CARTO_API_CALL_SUCCESS,
  FETCH_CODE_TITLE,
  getFormattedResults,
  getRawResults,
  getInitialLoad,
} from '../reducers/loadCarto';
import { getCountDictionary, LOAD_COUNT_DICTIONARY_SUCCESS } from '../reducers/dictionaries';
import { getWhereSearch, UPDATE_SELECTION, REMOVE_SELECTION } from '../reducers/cartoParams';
import store from '../store';
import objectValues from '../tools/objectValues';
import { initialURL, setURLparams } from '../tools/setURLparams';
import splitCartoResult from '../tools/splitCartoResult';
import config from '../config/default';
import buildPlaceSql from '../tools/buildPlaceSql';
import dbStringInput from '../tools/dbStringInput';
import scrollTo from '../tools/scrollTo';

const { tableSuffix } = config;

// request title of a specifc code
function* fetchTitle(where) {
  // if not searching for a specific code, clear the current title and return
  if (!where.nfpanumber) {
    yield put({ type: FETCH_CODE_TITLE, payload: null });
    return;
  }
  // otherwise, set up the sequel and fetch the tile, store it in state.
  const sql = `
    SELECT DISTINCT document_edition.title FROM codefinder.document_edition
    INNER JOIN codefinder.document ON document_edition.documentid = document.documentid
    WHERE number = ${dbStringInput(where.nfpanumber)}
  `;
  const title = yield fetch(`https://${config.username}.carto.com/api/v2/sql?q=${sql.replace(/\s\s+/g, ' ')}&api_key=${config.cartoKey}`)
    .then((response) => response.json());
  yield put({ type: FETCH_CODE_TITLE, payload: title.rows[0].title });
}

// request data from Carto
function fetchCarto({ where, loadCodes }) {
  // build SQL strings for each search level, if a parameter is specified
  const { countrySql, stateSql, countySql, citySql, agencySql } = buildPlaceSql(where, true);

  // set up loadCodes SQL
  const codeSql = `
    dea.documenteditionadoptionid deaid,
    dea.amended amended,
    dea.parentid parentid,
    dea.referencedtype referencedtype,
    'nfpanumber-'||doc.number nfpanumber,
    doc.number docnumber,
    de.title title,
    doc.title titlealt,
    de.editionyear edition,
    de.codenumberprefix codetype,
    de.codenumbersuffix suffix,
    string_to_array(de.referencedpublications, ', ') reference,
    org.region_code stateshort
  `;
  const codeOrderSql = ', referencedtype, nfpanumber';
  const codeGroupSql = ', amended, parentid, referencedtype, nfpanumber, docnumber, title, titlealt, edition, codetype, suffix, reference, stateshort';

  // build the SQL
  const sql = `
    SELECT *
    FROM (
      SELECT
        'country-'||countrynames.long country,
        'state-'||statenames.name state,
        'county-'||county.name county,
        'city-'||city.name city,
        'agency-'||org.name agency,
        ${loadCodes ? codeSql : '\'deaid\' deaid'}
      FROM nfpa_organization${tableSuffix} org
        LEFT OUTER JOIN nfpa_county${tableSuffix} county ON org.county_id = county.county_id
        LEFT OUTER JOIN nfpa_city${tableSuffix} city ON org.city_id = city.city_id
        INNER JOIN document_edition_adoption${tableSuffix} dea ON dea.agencyid = org.organization_id AND dea.status = 'Current Adoption'
        INNER JOIN document_edition${tableSuffix} de ON de.documenteditionid = dea.documenteditionid
        INNER JOIN document${tableSuffix} doc ON doc.documentid = de.documentid
        LEFT OUTER JOIN country_translation countrynames ON countrynames.short = org.country_code
        LEFT OUTER JOIN ne_50m_admin_1_states_provinces_lakes statenames ON statenames.postal = org.region_code AND statenames.iso_a2 = org.country_code
      ${process.env.REACT_APP_NEC ? 'WHERE doc.number = \'70\'' : ''}
    ) results
    WHERE agency != 'agency-Agency not identified'${countrySql}${stateSql}${countySql}${citySql}${agencySql}
    GROUP BY country, state, county, city, agency, deaid${loadCodes ? codeGroupSql : ''}
    ORDER BY (CASE country when 'country-United States of America' THEN 0 ELSE 1 END), country, state, county, city, agency${loadCodes ? codeOrderSql : ''}
  `;
  return fetch(`https://${config.username}.carto.com/api/v2/sql?${qs.stringify({
    api_key: config.cartoKey,
    q: sql,
  })}`)
    .then((response) => response.json())
    .catch((error) => console.log('load carto error: ', error.message));
}

// Function further structuring results into objects with a type, name, and row object
function makeRows(data) {
  return Object.keys(data).map((key) => {
    const [type, name] = key.split(/-(.+)/);
    const value = data[key];
    if (!name) return value;
    return { type, name, rows: makeRows(value) };
  });
}

// worker saga that fires the api call function when the watcher saga sees the action
function* workerCarto({ payload }) {
  try {
    // check if it's the first load
    const initialLoad = yield select(getInitialLoad);

    // check to see if the data is already loaded
    let loadCodes = false;
    const where = yield select(getWhereSearch);
    yield call(fetchTitle, where);
    if (where.agency) {
      // load the data, and check to see if the data exists
      // first isolate the section of the data pertaining to your search
      let loadedData = yield select(getFormattedResults);
      ['country', 'state', 'county', 'city', 'agency'].forEach((level) => {
        if (where[level]) {
          loadedData.forEach((row) => {
            if (row.type === level && row.name === where[level]) loadedData = row.rows;
          });
        }
      });
      // check the specific data section - if it has no code data loaded, set loadCodes to true
      if (!loadedData[0].nfpanumber) loadCodes = true;
    }
    // if the data is already loaded, don't fire the CARTO call
    if (!loadCodes && !initialLoad) {
      yield put({ type: CARTO_API_CANCEL });
    } else {
      // fire loadCarto request, with specific requests as necessary
      yield put({ type: FETCHING });
      const resultObj = yield call(fetchCarto, { where, loadCodes });
      let rawResults = resultObj.rows;

      if (loadCodes) {
        // check if new result codes have children
        rawResults.forEach((code) => {
          if (_.compact(rawResults.filter((subCode) => subCode.parentid === code.deaid)).length === 0) {
            code.nochildren = true;
          } else {
            code.nochildren = false;
          }
        });
        // merge new results with existing data at the index of the agency being loaded
        const loadedRawResults = yield select(getRawResults);
        const insertIndex = loadedRawResults.map((result) => result.agency).indexOf(`agency-${where.agency}`);
        loadedRawResults.splice(insertIndex, 0, ...rawResults);
        rawResults = loadedRawResults;
      }

      // parse results into grouped object by reducing array to one object using keys and set-in package
      // Limit values to nest by to organization level, the 5th level
      let results = rawResults.reduce((acc, row) => {
        // dictate number of nests based on whether countries are being loaded, or everything
        const nests = objectValues(row).slice(0, 6).filter((attribute) => attribute);
        return setIn(acc, nests, row);
      }, {});
      results = makeRows(results);

      // add aliases to rawResults
      const { searchLookup } = config;
      rawResults.forEach((result) => {
        if (!result.nfpanumber) return;
        const nfpanumber = splitCartoResult(result.nfpanumber, 1);
        if (_.includes(Object.keys(searchLookup), nfpanumber)) {
          result.alias = searchLookup[nfpanumber];
        } else {
          result.alias = null;
        }
        result.docnumber = parseInt(result.docnumber, 10);
      });

      const payload = { results, rawResults };
      // initialize the codes in state
      yield put({ type: CARTO_API_CALL_SUCCESS, payload });
    }
    if (initialLoad) {
      yield put({ type: COMPLETE_INITIAL_LOAD });
      // update params if they're included in the initial URL, and update the page
      initialURL();
    }
    // up date the query parameters
    if (!initialLoad) {
      // make the website URL to reflect the new params
      yield setURLparams(store);
    }
    // if necessary, scroll to the proper element in the results list
    const hasDictionary = yield select(getCountDictionary);
    if (!hasDictionary) yield take(LOAD_COUNT_DICTIONARY_SUCCESS);
    scrollTo(payload);
  } catch (error) {
    // dispatch error action with error to store
    yield put({ type: CARTO_API_CALL_ERROR, error });
  }
}

// watcher function to track actions to store, start loadCarto saga
export default function* watchCartoSaga() {
  yield takeLatest([CARTO_API_CALL_REQUEST, UPDATE_SELECTION, REMOVE_SELECTION], workerCarto);
}


// const sql = `
//     SELECT * FROM
//     (SELECT DISTINCT
//       countrynames.long country,
//       statenames.name state
//       FROM nfpa_organization org
//         LEFT OUTER JOIN nfpa_county county ON org.county_id = county.county_id
//         LEFT OUTER JOIN nfpa_city city ON org.city_id = city.city_id
//         INNER JOIN document_edition_adoption dea ON dea.agencyid = org.organization_id AND dea.status = 'Current Adoption'
//         INNER JOIN document_edition de ON de.documenteditionid = dea.documenteditionid
//         INNER JOIN document doc ON doc.documentid = de.documentid
//         LEFT OUTER JOIN country_translation countrynames ON countrynames.short = org.country_code
//         LEFT OUTER JOIN ne_50m_admin_1_states_provinces_lakes statenames ON statenames.postal = org.region_code AND statenames.iso_a2 = org.country_code
//     ) results
//     ORDER BY (CASE country when 'United States of America' THEN 0 ELSE 1 END), country, state
//   `;

// const sql = `
//     SELECT *
//     FROM (
//       SELECT
//         'country-'||countrynames.long country,
//         'state-'||statenames.name state,
//         'county-'||county.name county,
//         'city-'||city.name city,
//         'agency-'||org.name agency,
//         dea.documenteditionadoptionid deaid,
//         dea.amended amended,
//         dea.parentid parentid,
//         dea.referencedtype referencedtype,
//         'nfpanumber-'||doc.number nfpanumber,
//         doc.number docnumber,
//         de.title title,
//         doc.title titlealt,
//         de.editionyear edition,
//         de.codenumberprefix codetype,
//         de.codenumbersuffix suffix,
//         string_to_array(de.referencedpublications, ', ') reference,
//         keywords ~~* '%25B8968454-B7CD-41DC-9991-6D7BE3743B0D%25' building,
//         keywords ~~* '%252D4A4779-8724-4412-B7FD-A426491F396A%25' fire,
//         keywords ~~* '%255B38EC3B-7464-44B0-895B-42BF20551F98%25' electrical,
//         keywords ~~* '%25F4DCD52A-748E-427D-BE67-85B6BDF8FF69%25' emergency,
//         keywords ~~* '%258ED6AEF3-21B7-444B-BCA6-1F499CDA9BBB%25' industrial,
//         org.region_code stateshort,
//         doc.keywords keywords
//       FROM nfpa_organization${tableSuffix} org
//         LEFT OUTER JOIN nfpa_county${tableSuffix} county ON org.county_id = county.county_id
//         LEFT OUTER JOIN nfpa_city${tableSuffix} city ON org.city_id = city.city_id
//         INNER JOIN document_edition_adoption${tableSuffix} dea ON dea.agencyid = org.organization_id AND dea.status = 'Current Adoption'
//         INNER JOIN document_edition${tableSuffix} de ON de.documenteditionid = dea.documenteditionid
//         INNER JOIN document${tableSuffix} doc ON doc.documentid = de.documentid
//         LEFT OUTER JOIN country_translation countrynames ON countrynames.short = org.country_code
//         LEFT OUTER JOIN ne_50m_admin_1_states_provinces_lakes statenames ON statenames.postal = org.region_code AND statenames.iso_a2 = org.country_code
//     ) results
//     WHERE agency != 'agency-Agency not identified' AND country = 'country-${country.country}'
//     ${country.state ? `AND state = 'state-${country.state}'` : ''}
//     ORDER BY (CASE country when 'country-United States of America' THEN 0 ELSE 1 END), country, state, county, city, agency, referencedtype, nfpanumber
//   `;
