import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Spinner from '../Spinner';
import InsetMap from '../map/InsetMap';

import { CARTO_API_CALL_REQUEST } from '../../reducers/loadCarto';
import { REMOVE_SELECTION } from '../../reducers/cartoParams';

import Table from './Table';
import TableUI from './table-ui';

const styles = (theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing.unit,
  },
  tableContainer: {
    height: '100vh',
  },
  tableResults: {
    maxHeight: '100%',
    boxShadow: '0px 8px 10px #888888',
    zIndex: 500,
  },
  mapToggle: {
    position: 'absolute',
    bottom: 0,
    left: theme.spacing.unit * 2,
    zIndex: 500,
    backgroundColor: 'white',
    paddingRight: 8,
  },
});

const TableContainer = ({ classes, results, error, clearParameters, mapExpand, visible }) => (
  <div className={classes.container}>
    <div className={classes.ui}>
      <TableUI clearParameters={clearParameters} mapExpand={mapExpand} />
    </div>
    {results ? (
      <Grid container className={classes.tableContainer}>
        {!mapExpand && visible ? (
          <React.Fragment>
            <Grid item xs={12} sm={9} className={classes.tableResults}>
              <Table results={results} error={error} />
            </Grid>
            <Grid item xs={false} sm={3}>
              <InsetMap />
            </Grid>
          </React.Fragment>
        ) : (
          <Grid item sm={12} className={classes.tableResults}>
            <Table results={results} error={error} />
          </Grid>
        )}
      </Grid>
    ) : <Spinner />}
  </div>
);

const mapStateToProps = (state) => ({
  results: state.loadCarto.formattedResults,
  error: state.loadCarto.error,
  mapExpand: state.map.expand,
  visible: state.map.visible,
});

const mapDispatchToProps = (dispatch) => ({
  clearParameters: () => {
    dispatch({ type: REMOVE_SELECTION, payload: 'country' });
    dispatch({ type: REMOVE_SELECTION, payload: 'nfpanumber' });
    dispatch({ type: CARTO_API_CALL_REQUEST });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TableContainer));
