import qs from 'querystring';
import store from '../store';
import history from '../history';

import objectValues from './objectValues';
import { updateSelection } from '../reducers/cartoParams';

// Update URL parameters
export const setURLparams = (store) => {
  const params = store.getState().cartoParams;

  const query = {};
  // add search params
  if (params.search) {
    query.search = `${objectValues(params.search)[0]}LIKE${objectValues(params.search)[1]}`;
  }
  // where (spatial) params
  Object.keys(params.where).map((whereKey) => {
    if (params.where[whereKey]) {
      query[whereKey] = `${params.where[whereKey]}`;
    }
    return true;
  });

  // update the app location
  history.replace({
    pathaname: history.location.pathname,
    search: `?${qs.stringify(query)}`,
  });
};

export const initialURL = () => {
  // initialize the route state
  const initialSearch = qs.parse(history.location.search.substring(1));
  if (initialSearch) {
    // initialize store state based on URL
    // where;
    const { state, county, city, agency, nfpanumber } = initialSearch;
    let { country } = initialSearch;
    if (process.env.REACT_APP_NEC && !country) country = 'United States of America';
    store.dispatch(updateSelection({
      country: country ? `country-${country}` : null,
      state: state ? `state-${state}` : null,
      county: county ? `county-${county}` : null,
      city: city ? `city-${city}` : null,
      agency: agency ? `agency-${agency}` : null,
    }));
    if (nfpanumber) store.dispatch(updateSelection({ nfpanumber: `nfpanumber-${nfpanumber}` }));
  }
};
