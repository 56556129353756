import { takeLatest, call, put } from 'redux-saga/effects';
import _ from 'lodash';
import L from 'leaflet';
import qs from 'querystring';
import { boundsURL } from '../tools/mapURL';

import { CARTO_API_CALL_SUCCESS, CARTO_API_CANCEL } from '../reducers/loadCarto';
import { UPDATE_MAP_BOUNDS_ERROR, UPDATE_MAP_BOUNDS_SUCCESS } from '../reducers/map';
import store from '../store';
import config from '../config/default';

function* updateBounds() {
  // set map bounds based on store
  const {
    cartoParams: { where: { country, state, county, city, nfpanumber } },
  } = store.getState();
  // provide pre-coded bounds if possible
  if (!country) {
    const mapBounds = L.latLngBounds([[70, -170], [-60, 60]]);
    return mapBounds;
  }
  if (country && !state && config.bounds.country[country]) {
    const mapBounds = L.latLngBounds(config.bounds.country[country]);
    return mapBounds;
  }
  if (state && config.bounds.state[state]) {
    const mapBounds = L.latLngBounds(config.bounds.state[state]);
    return mapBounds;
  }
  // do nothing if a city or county is selected
  if (county || city) {
    return;
  }
  // generate SQL call for bounds
  const boundsSQL = boundsURL(country, state, county, city, nfpanumber);
  const response = yield fetch(`https://${config.username}.carto.com/api/v2/sql?${qs.stringify({
    api_key: config.cartoKey,
    q: boundsSQL,
  })}`)
    .catch((error) => console.log('update map bounds error: ', error.message));
  if (response.status > 200) {
    throw new Error('failure to load bounds');
  }
  const json = yield response.json();

  // otherwise, use latLngBounds
  const boundCoords = [];
  json.rows.forEach((place) => {
    const coords = JSON.parse(place.envelope).coordinates[0];
    coords.map((coord) => boundCoords.push(_.reverse(coord)));
  });
  const mapBounds = L.latLngBounds(boundCoords);
  return mapBounds;
}

// worker saga that fires the api call function when the watcher saga sees the action
function* workerBounds() {
  try {
    // generate map bounds based on state
    const bounds = yield call(updateBounds);
    yield put({ type: UPDATE_MAP_BOUNDS_SUCCESS, bounds });
  } catch (error) {
    console.log(error);
    // dispatch error action with error to store
    yield put({ type: UPDATE_MAP_BOUNDS_ERROR, error });
  }
}

// watcher function to track actions to store, start loadCarto saga
export default function* watchMapUpdateSaga() {
  yield takeLatest([CARTO_API_CALL_SUCCESS, CARTO_API_CANCEL], workerBounds);
}
