import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@material-ui/core/Link';

import ArrowTooltip from '../../../ArrowTooltip';

const styles = {
  feedback: {
    display: 'inline',
  },
  button: {
    color: 'white',
    padding: 6,
    '&:hover': {
      backgroundColor: '#333',
    },
  },
};

const Help = ({ classes, collapse, handleClose }) => (
  <div className={classes.feedback}>
    {collapse ? (
      <Link href="https://www.nfpa.org/CodeFinderFAQ/" target="_blank" rel="noopener noreferrer">
        <MenuItem onClick={handleClose}>
          <Typography variant="button">Frequently Asked Questions</Typography>
        </MenuItem>
      </Link>
    ) : (
      <a href="https://www.nfpa.org/CodeFinderFAQ/" target="_blank" rel="noopener noreferrer">
        <ArrowTooltip title="Frequently Asked Questions" placement="bottom" adjust>
          <IconButton aria-label="help" className={classes.button}>
            <HelpIcon />
          </IconButton>
        </ArrowTooltip>
      </a>
    )}
  </div>
);

export default withStyles(styles)(Help);
