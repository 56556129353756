import { combineReducers } from 'redux';
import dictionaries from './dictionaries';
import disclaimer from './disclaimer';
import loadCarto from './loadCarto';
import cartoParams from './cartoParams';
import map from './map';
import splitPane from './splitPane';

// combine all app reducers together
export default combineReducers({
  dictionaries,
  disclaimer,
  loadCarto,
  cartoParams,
  map,
  splitPane,
});
