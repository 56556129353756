// import geostats from 'geostats';
// import _ from 'lodash';

import { hasEditions as noCode } from '../config/default';

const cssStyle = ({ nfpanumber, breaks, colors }) => {
  const ourColors = colors.slice().reverse();
  console.log('breaks1', breaks);
  if (breaks[0] === null) {
    breaks[0] = '0';
  }
  // create style object with three sub-objects: style for cities, style for main layer, style for parent
  const style = {};
  style.polyStyle = `#layer {
    ${nfpanumber ? `
      polygon-fill: #999999;
      ${
  breaks.slice().reverse().map((brek, i) => {
    if (brek.startsWith('<')) {
      return `[edition ${brek}] { polygon-fill: ${ourColors[i]}; }`;
    }
    return `[edition = ${brek}] { polygon-fill: ${ourColors[i]}; }`;
  }).reverse()
    .join('\n')
}
      [edition = 0] { polygon-fill: #999999; }
      polygon-opacity: 1;
      [edition = null] { polygon-fill: ${noCode}; polygon-opacity: 0.3; }
      ` : `
      polygon-fill: ${noCode};
      polygon-opacity: 0.3;
    `}
    [zoom >= 11] { polygon-opacity: 0; }
    ::outline {
      ${nfpanumber ? 'line-color: #7c021f;' : 'line-color: #ffffff;'}
      line-width: 1;
      [zoom >= 4] { line-width: 1; }
      [zoom >= 8] { line-width: 2; }
      [zoom >= 12] { line-width: 3; }
      [zoom >= 14] { line-width: 0; }
      [edition = null] { line-width: 0; }
    }
  }`;
  style.pointStyle = `#layer {
    marker-width: 20.0;
      [zoom < 8] { marker-width: 12; marker-line-width: 1; }
      [zoom >= 8] { marker-width: 16; marker-line-width: 2; }
      [zoom >= 12] { marker-width: 20; }
      [zoom >= 14] { marker-width: 24; marker-line-width: 3; }
    ${nfpanumber ? `
    marker-fill: #999999;
      [edition >= ${breaks[4]}] { marker-fill: ${colors[4]}; }
      [edition < ${breaks[4]}] { marker-fill: ${colors[3]}; }
      [edition < ${breaks[3]}] { marker-fill: ${colors[2]}; }
      [edition < ${breaks[2]}] { marker-fill: ${colors[1]}; }
      [edition < ${breaks[1]}] { marker-fill: ${colors[0]}; }
      [edition = 0] { marker-fill: #999999; }
      ` : `marker-fill: ${noCode};`}
      ${nfpanumber ? 'marker-line-color: #7c021f;' : 'marker-line-color: #ffffff;'}
  }`;
  return style;
};

export default cssStyle;
