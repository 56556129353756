import dbStringInput from './dbStringInput';

export default (where, loadCarto) => {
  // build SQL strings for each search level, if a parameter is specified
  const placeSql = {};
  const placeColumns = {
    country: 'countrynames.long',
    state: 'statenames.name',
    county: 'county.name',
    city: 'city.name',
    agency: 'org.name',
  };
  ['country', 'state', 'county', 'city', 'agency', 'nfpanumber'].forEach((level) => {
    if (where[level]) {
      placeSql[`${level}Sql`] = ` AND ${loadCarto ? level : placeColumns[level]} = ${dbStringInput(`${loadCarto ? `${level}-` : ''}${where[level]}`)}`;
    } else placeSql[`${level}Sql`] = '';
  });
  return placeSql;
};
